import React from "react";
import ReactDOM from "react-dom";
// import ReactDOM from "react-dom/client"

import { BrowserRouter } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import 'mapbox-gl/dist/mapbox-gl.css';

import "./assets/css/turrim-app.min.css";
import './assets/css/app.css';
import "./assets/css/turrim.css";
import "./assets/css/MaterialSymbols.css";
import "./assets/css/Sidebar.css";
import './assets/css/map_styles.css';
import "./assets/css/ErrorMessage.css";
import "./assets/css/ScrollableTextArea.css";
import "./assets/css/FlatfileContainer.css";
import "./assets/css/ElectionCard.css";
import "./assets/css/PhoneBanking.css";
import "./assets/css/CustomModal.css";
import "./assets/css/LoadingLogo.css";
import "./assets/css/Filter.css";
import "./assets/css/Survey.css";
import "./assets/css/SelectFix.css";
import "./assets/css/MapFilters.css";

import "./assets/css/AddWalkbookButton.css";
import "./assets/css/SendMailer.css";




import App from './App';

ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
  document.getElementById("root")
);

// const root = ReactDOM.createRoot(document.getElementById("root"))
// root.render(<App />)

// ReactDOM.createRoot(document.getElementById('root')).render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>
// );