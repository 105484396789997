import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';

import PulsingLogo from '../Loading/PulsingLogo';

// import { useUserContext } from "../../contexts/UserContext";

const LocalLoadingSpinner = ({ loading_message }) => {


  if (!loading_message || loading_message === '') {
    return null;
  }

  return (
    <>
      <div className="content d-flex align-items-center p-0">
        <Container>
          <Col className="mx-auto" lg="6" md="8">
            <Card className="card-login">
              <Card.Body>
                <h3 className="header text-center">{loading_message}</h3>
                {" "}<br />
                <Row className="justify-content-center">
                  {/* <Spinner animation="border" /> */}
                  <PulsingLogo />
                </Row>
                {" "}<br />
              </Card.Body>
              <Card.Footer className="ml-auto mr-auto">
              </Card.Footer>
            </Card>
          </Col>
        </Container>
      </div>
    </>
  );
}

export default LocalLoadingSpinner;