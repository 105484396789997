import React, { useState } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';

function CampaignInvitationSelectionModal({ campaigns, onSelect, invitations, onSelectInvitation }) {

    const [expandedSection, setExpandedSection] = useState('campaigns');

    // Handle click on a campaign card
    const handleClick = (index) => {
        // onSelect(index.toString());
        onSelect(campaigns[index]);
    };

    const handleInvitationClick = (index) => {
        onSelectInvitation(index.toString());
    };

    const numberOfDuplicates = 8;
    let duplicatedcampaigns = [];

    if (campaigns.length > 0) {
        const firstcampaigns = campaigns[0];

        for (let i = 0; i < numberOfDuplicates; i++) {
            duplicatedcampaigns.push({ ...firstcampaigns });
        }
    }

    // const numberOfDuplicates = 8;
    let duplicatedinvitations = [];

    if (invitations.length > 0) {
        const firstinvitations = invitations[0];

        for (let i = 0; i < numberOfDuplicates; i++) {
            duplicatedinvitations.push({ ...firstinvitations });
        }
    }

    const toggleSection = (section) => {
        if (expandedSection === section) {
            // If clicking on the open section, close it and open the other
            setExpandedSection(section === 'campaigns' ? 'invitations' : 'campaigns');
        } else {
            // If clicking on the closed section, open it
            setExpandedSection(section);
        }
    };

    // const renderSectionHeader = (title, section, count) => (
    //     <div className="d-flex justify-content-between align-items-center" style={{ paddingLeft: '15px', paddingRight: '17px' }}>
    //         {/* <Card.Title style={{ fontSize: "18px", margin: 0 }}> */}
    //         <Card.Title
    //             style={{ maxWidth: '475px', width: '100%', padding: '10px', fontSize: "18px" }}
    //         // onClick={() => toggleSection(section)}
    //         >
    //             {title}
    //         </Card.Title>
    //         <span className="material-icons-round" style={{ padding: '10px', cursor: 'pointer' }} onClick={() => toggleSection(section)}>
    //             {expandedSection === section ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
    //         </span>
    //     </div>
    // );

    const renderSectionHeader = (title, section, count) => (
        <div className="d-flex justify-content-between align-items-center" style={{ paddingLeft: '15px', paddingRight: '17px' }}>
            {/* <div className="d-flex align-items-center">
                <Card.Title
                    style={{ padding: '10px', fontSize: "18px", margin: 0 }}
                >
                    {title}
                </Card.Title>
                <span
                    style={{
                        marginLeft: '10px',
                        borderBottom: '2px solid #007bff',
                        paddingBottom: '2px',
                        paddingLeft: '4px',
                        paddingRight: '4px',
                        fontSize: '14px',
                        position: 'relative',
                        cursor: 'pointer'
                    }}
                    onClick={() => toggleSection(section)}
                >
                    {count}
                    <span style={{
                        content: '""',
                        position: 'absolute',
                        bottom: '-2px',
                        left: '-3px',
                        right: '-3px',
                        height: '2px',
                        backgroundColor: '#007bff'
                    }}></span>
                </span>
            </div> */}
            {/* <span
                className="material-icons-round"
                style={{ padding: '10px', cursor: 'pointer' }}
                onClick={() => toggleSection(section)}
            >
                {expandedSection === section ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            </span> */}

            <Card.Title
                style={{ padding: '10px', fontSize: "18px", margin: 0 }}
            >
                {title}
            </Card.Title>
            <div
                className="d-flex align-items-center"
                style={{ padding: '10px', cursor: 'pointer' }}
                onClick={() => toggleSection(section)}
            >
                {/* <span style={{
                    marginRight: '10px',
                    fontSize: '14px',
                    color: '#6c757d'  // Using a muted color for the count
                }}>
                    ({count})
                </span> */}
                <span
                    style={{
                        marginRight: '10px',
                        borderBottom: '2px solid #007bff',
                        paddingBottom: '1px',
                        paddingLeft: '4px',
                        paddingRight: '4px',
                        fontSize: '14px',
                        position: 'relative',
                        cursor: 'pointer'
                    }}
                    onClick={() => toggleSection(section)}
                >
                    {count}
                    <span style={{
                        content: '""',
                        position: 'absolute',
                        bottom: '-2px',
                        left: '-3px',
                        right: '-3px',
                        height: '2px',
                        backgroundColor: '#007bff'
                    }}></span>
                </span>
                <span
                    className="material-icons-round"
                // style={{ cursor: 'pointer' }}
                // onClick={() => toggleSection(section)}
                >
                    {expandedSection === section ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                </span>
            </div>
        </div>
    );

    const renderCampaigns = () => (
        <>
            {renderSectionHeader('Campaigns', 'campaigns', campaigns.length)}
            {expandedSection === 'campaigns' && (
                campaigns?.length > 0 ? (
                    <Card
                        className="card-scroll-div-wrapper"
                        style={{ backgroundColor: 'transparent', borderRadius: '4px', margin: '0', borderWidth: '0', maxHeight: '450px', overflowY: 'auto', maxWidth: '425px', width: '100%', paddingLeft: '0px', paddingRight: '0px' }}
                    >
                        <Card.Body style={{ paddingLeft: '10px', paddingTop: '5px', paddingRight: '10px', paddingBottom: '0px', width: '100%' }}>
                            {campaigns.map((campaign, index) => (
                            // {/* {duplicatedcampaigns.map((campaign, index) => ( */}
                                <Card
                                    key={index}
                                    className="campaign-selection-card mb-2"
                                    onClick={() => handleClick(index)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Card.Body style={{ padding: '10px' }}>
                                        <Card.Title style={{ fontSize: "18px" }}>
                                            {campaign.campaign_name}
                                        </Card.Title>
                                        <Card.Text>
                                            Candidate: {campaign.candidate_name}<br />
                                            Title: {campaign.title}<br />
                                        </Card.Text>
                                        {/* <Row>
                                            <Col>
                                                <Card.Text>
                                                    Candidate: {campaign.candidate_name}<br />
                                                </Card.Text>
                                            </Col>
                                            <Col>
                                                <Card.Text>
                                                    Title: {campaign.title}<br />
                                                    Joined: {campaign.date_joined}
                                                </Card.Text>
                                            </Col>
                                        </Row> */}
                                    </Card.Body>
                                </Card>
                            ))}
                        </Card.Body>
                    </Card>
                ) : (
                    <div className="text-center" style={{ padding: '15px 15px 35px 15px' }}>
                        No campaigns found.
                    </div>
                )
            )}
        </>
    );

    const renderInvitations = () => (
        <>
            {renderSectionHeader('Invitations', 'invitations', invitations.length)}
            {expandedSection === 'invitations' && (
                invitations?.length > 0 ? (
                    <Card
                        className="card-scroll-div-wrapper"
                        style={{ backgroundColor: 'transparent', borderRadius: '4px', margin: '0', borderWidth: '0', maxHeight: '450px', overflowY: 'auto', maxWidth: '425px', width: '100%', paddingLeft: '0px', paddingRight: '0px' }}
                    >
                        <Card.Body style={{ paddingLeft: '10px', paddingTop: '5px', paddingRight: '10px', paddingBottom: '0px', width: '100%' }}>
                            {invitations.map((invitation, index) => (
                            // {/* {duplicatedinvitations.map((invitation, index) => ( */}
                                <Card
                                    key={index}
                                    className="campaign-selection-card mb-2"
                                    onClick={() => handleInvitationClick(index)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Card.Body style={{ padding: '10px' }}>
                                        <Card.Title style={{ fontSize: "18px" }}>
                                            {invitation.campaign_name}
                                        </Card.Title>
                                        <Card.Text>
                                            Candidate: {invitation.candidate_name}<br />
                                            From: {invitation.sender_email}<br />
                                            Offer Title: {invitation.offer_title}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            ))
                            }
                        </Card.Body>
                    </Card>
                ) : (
                    <div className="text-center" style={{ padding: '15px 15px 35px 15px' }}>
                        No invitations found.
                    </div>
                )
            )}
        </>
    );

    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1050
            }}
        >
            <Card style={{ width: '475px', backgroundColor: '#efeff2' }}>
                <Card.Title as="h4" style={{ padding: '10px' }}>
                    Select a Campaign or Invitation
                </Card.Title>
                <hr className="horizontal-divider" style={{ marginTop: "0px", marginBottom: '15px' }} />

                <Row className="justify-content-center">
                    {renderCampaigns()}
                </Row>

                <Row className="justify-content-center">
                    {renderInvitations()}
                </Row>
            </Card>
        </div>
    );
}

export default CampaignInvitationSelectionModal;

//     return (
//         <>
//             <div
//                 className="d-flex justify-content-center align-items-center"
//                 style={{
//                     position: 'fixed',
//                     top: 0,
//                     left: 0,
//                     right: 0,
//                     bottom: 0,
//                     // backgroundColor: 'rgba(0,0,0,0.5)',
//                     zIndex: 1050
//                 }}
//             >
//                 <Card style={{ width: '475px', backgroundColor: '#efeff2' }}>
//                     <Card.Title
//                         as="h4"
//                         style={{ padding: '10px' }}
//                     // style={{ fontSize: "18px", color: "rgb(107,114,128)" }}
//                     >
//                         Select a Campaign or Invitation
//                     </Card.Title>
//                     <hr className="horizontal-divider" style={{ marginTop: "0px", marginBottom: '15px' }} />

//                     <Row className="justify-content-center">
//                         <Card.Title
//                             style={{ maxWidth: '475px', width: '100%', padding: '0px 10px 10px', fontSize: "18px" }}
//                         >
//                             Campaigns
//                         </Card.Title>
//                         {campaigns?.length > 0 ? (
//                             <Card
//                                 className="card-scroll-div-wrapper"
//                                 style={{ backgroundColor: 'transparent', borderRadius: '4px', margin: '0', borderWidth: '0', maxHeight: '300px', height: 'auto', maxWidth: '425px', width: '100%', paddingLeft: '0px', paddingRight: '0px' }}
//                             // style={{ backgroundColor: 'transparent', borderRadius: '4px', margin: '0', borderWidth: '0', maxHeight: '800px', height: '100vh', maxWidth: '675px', width: '100%', paddingLeft: '0px', paddingRight: '0px' }}
//                             >
//                                 <Card.Body
//                                     style={{ paddingLeft: '10px', paddingTop: '5px', paddingRight: '10px', paddingBottom: '0px', width: '100%' }}
//                                 >
//                                     {/* {campaigns.map((campaign, index) => { */}
//                                     {duplicatedcampaigns.map((campaign, index) => {

//                                         return (
//                                             <Card
//                                                 key={index}
//                                                 className="campaign-selection-card"
//                                                 onClick={() => handleClick(index)}
//                                                 style={{ cursor: 'pointer' }}
//                                             >
//                                                 <Card.Body style={{ padding: '0px' }}>
//                                                     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '10px' }}>
//                                                         <Card.Title
//                                                             style={{ fontSize: "18px" }}
//                                                         >
//                                                             {campaign.campaign_name}
//                                                         </Card.Title>
//                                                     </div>
//                                                     {/* <Card.Title>{campaign.candidate_name}</Card.Title> */}
//                                                     <Row>
//                                                         <Col>

//                                                             <Card.Text>
//                                                                 Candidate: {campaign.candidate_name}<br />
//                                                             </Card.Text>
//                                                         </Col>
//                                                         <Col>
//                                                             <Card.Text>
//                                                                 Title: {campaign.title}<br />
//                                                                 Joined: {campaign.date_joined}
//                                                             </Card.Text>
//                                                         </Col>
//                                                     </Row>
//                                                 </Card.Body>
//                                             </Card>
//                                         );

//                                     })}
//                                 </Card.Body>
//                             </Card>
//                         ) : (
//                             <div className="text-center" style={{ padding: '15px 15px 35px 15px' }}>
//                                 No campaigns found.
//                             </div>
//                         )}
//                     </Row>



//                     <Row className="justify-content-center">
//                         <hr className="horizontal-divider" style={{ maxWidth: '475px', width: '100%', marginTop: "0px", marginBottom: '15px' }} />
//                         <Card.Title
//                             style={{ maxWidth: '475px', width: '100%', padding: '0px 10px 10px', fontSize: "18px" }}
//                         >
//                             Invitations
//                         </Card.Title>
//                         {invitations?.length > 0 ? (
//                             <Card
//                                 className="card-scroll-div-wrapper"
//                                 style={{ backgroundColor: 'transparent', borderRadius: '4px', margin: '0', borderWidth: '0', maxHeight: '300px', height: 'auto', maxWidth: '425px', width: '100%', paddingLeft: '0px', paddingRight: '0px' }}
//                             // style={{ backgroundColor: 'transparent', borderRadius: '4px', margin: '0', borderWidth: '0', maxHeight: '800px', height: '100vh', maxWidth: '675px', width: '100%', paddingLeft: '0px', paddingRight: '0px' }}
//                             >
//                                 <Card.Body
//                                     style={{ paddingLeft: '10px', paddingTop: '5px', paddingRight: '10px', paddingBottom: '0px', width: '100%' }}
//                                 >
//                                     {/* {invitations.map((invitation, index) => { */}
//                                     {duplicatedinvitations.map((invitation, index) => {
//                                         // console.log("invitation: ", invitation);

//                                         return (
//                                             <Card
//                                                 key={index}
//                                                 className="campaign-selection-card"
//                                                 onClick={() => handleInvitationClick(index)}
//                                                 style={{ cursor: 'pointer' }}
//                                             >
//                                                 <Card.Body style={{ padding: '0px' }}>
//                                                     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '10px' }}>
//                                                         <Card.Title
//                                                             style={{ fontSize: "18px" }}
//                                                         >
//                                                             {invitation.campaign_name}
//                                                         </Card.Title>
//                                                     </div>
//                                                     <Card.Text>
//                                                         Candidate: {invitation.candidate_name}<br />
//                                                     </Card.Text>
//                                                     <Row style={{ paddingBottom: '5px' }}>
//                                                         <Col>
//                                                             <Card.Text>
//                                                                 From: {invitation.sender_email}
//                                                             </Card.Text>
//                                                         </Col>
//                                                     </Row>

//                                                     <Row>
//                                                         <Col>
//                                                             <Card.Text>
//                                                                 Offer Title: {invitation.offer_title}
//                                                             </Card.Text>
//                                                         </Col>
//                                                     </Row>
//                                                     {/* <Row>
//                                                         <Col>
//                                                             <Card.Text>
//                                                                 Message: {invitation.message}
//                                                             </Card.Text>
//                                                         </Col>
//                                                     </Row> */}
//                                                 </Card.Body>
//                                             </Card>
//                                         );

//                                     })}
//                                 </Card.Body>
//                             </Card>
//                         ) : (
//                             <div className="text-center" style={{ padding: '15px 15px 35px 15px' }}>
//                                 No invitations found.
//                             </div>
//                         )}
//                     </Row>

//                 </Card>
//             </div>
//         </>
//     );
// }

// export default CampaignInvitationSelectionModal;
