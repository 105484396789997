import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CryptoJS from 'crypto-js';


// react-bootstrap components
import {
    Alert,
    Button,
    Card,
    Form,
    FloatingLabel,
    Container,
    Col,
    Row
} from "react-bootstrap";


import authAPI from '../../apis/auth/auth';
import campaignAPI from '../../apis/campaign/campaign';
import walkbookAPI from '../../apis/walkbook/walkbook';
import roadSignAPI from '../../apis/road_sign/road_sign';
import recommendedWalkbookAPI from '../../apis/walkbook/recommended_walkbook';
import voterGroupAPI from '../../apis/voter_group/voter_group';
import surveyAPI from '../../apis/survey/survey';
import voterTagsAPI from '../../apis/voter_tags/voter_tags';
import textCampaignAPI from '../../apis/text_campaign/text_campaign';
import invitationAPI from '../../apis/invitation/invitation';
// import noteBoardAPI from '../../apis/board/note_board';


import LoadingSpinner from "../../components/Spinner/LoadingSpinner";
import PulsingLogo from "../../components/Loading/PulsingLogo";
import CampaignInvitationSelectionModal from "../../components/Login/CampaignInvitationSelectionModal";
import { useUserContext } from "../../contexts/UserContext";
import { auth } from "../../contexts/Firebase";
import { set } from "lodash";


const secretKey = process.env.REACT_APP_SECRET_KEY;

const decryptData = (data) => {
    const bytes = CryptoJS.AES.decrypt(data, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};


function CampaignInvitationSelectPage() {

    const {
        campaignUser,
        setCampaignUser,
        currentUser,
        setUser,
        loading,
        setLoading,
        setInvitation,
        setCampaign,
        setWalkbooks,
        setRoadSigns,
        setRecommendations,
        setVoterGroups,
        setSurveys,
        setVoterTags,
        setLoadingCampaign,
        setLoadingWalkbooks,
        setLoadingRoadSigns,
        setLoadingVoterGroups,
        setLoadingSurveys,
        setLoadingRecommendations,

    } = useUserContext();

    const history = useHistory();


    const [error, setError] = useState('');

    const isMounted = useRef(false);
    const abortControllerRef = useRef(null);


    const [userResult, setUserResult] = useState(null);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
            // Cleanup function runs when the component unmounts
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, []);


    const handleCampaignSelect = async (selectedCampaign) => {


        if (!userResult || selectedCampaign === null) return;

        if (!selectedCampaign) return;

        // Cancel any previous pending request
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        // Create a new AbortController for this request
        abortControllerRef.current = new AbortController();
        const signal = abortControllerRef.current.signal;


        const userData = {
            ...userResult,
            title: selectedCampaign.title,
            campaign_id: selectedCampaign.campaign_id,
            date_joined: selectedCampaign.date_joined,
        };

        const campaignUserData = {
            title: selectedCampaign.title,
            campaign_id: selectedCampaign.campaign_id,
            date_joined: selectedCampaign.date_joined,
        };

        // localStorage.setItem('campaignUser', JSON.stringify(campaignUserData));


        // setShowCampaignInvitationSelection(false);

        if (isMounted.current) {
            setLoading("Loading data...");
            setLoadingCampaign("Loading campaign...");
            // setLoadingWalkbooks("Loading walkbooks...");
            // setLoadingRoadSigns("Loading road signs...");
            // setLoadingVoterGroups("Loading voter groups...");
            // setLoadingSurveys("Loading surveys...");
            // setLoadingRecommendations("Loading recommendations...");
            // // setLoadingTextCampaigns("Loading text campaigns...");
        }


        



        try {
            // Fetch campaign-specific data

            // const campaignUser = await authAPI.getCampaignUser(campaign.campaign_id);

            if (campaignUserData) {
                const firebaseToken = await currentUser.getIdToken();
                const campaignResult = await campaignAPI.getCampaign(firebaseToken, campaignUserData.campaign_id, signal);




                // Based on user title, fetch more data
                if (campaignUserData.title === 'Manager' || campaignUserData.title === 'Director') {
                    // const walkbooksResult = await walkbookAPI.getAllWalkbooks(firebaseToken, campaignUserData.campaign_id, signal);
                    // const roadSignsResult = await roadSignAPI.getAllRoadSigns(firebaseToken, campaignUserData.campaign_id, signal);
                    // const recommendationsResult = await recommendedWalkbookAPI.getAllRecommendations(firebaseToken, campaignUserData.campaign_id, signal);
                    // const voterGroupsResult = await voterGroupAPI.getAllVoterGroups(firebaseToken, campaignUserData.campaign_id, signal);
                    // const surveysResult = await surveyAPI.getAllSurveys(firebaseToken, campaignUserData.campaign_id, signal);
                    // const voterTagsResult = await voterTagsAPI.getAllTags(firebaseToken, signal);
                    // // const textCampaignsResult = await textCampaignAPI.getAllTextCampaigns(firebaseToken, campaignUserData.campaign_id);

                    // Update the global state with the fetched data
                    if (isMounted.current) {
                        setCampaignUser(campaignUserData);
                        setUser(userData);
                        setCampaign(campaignResult);
                        setLoadingCampaign("");
                        // setWalkbooks({ type: 'REPLACE', newWalkbooks: walkbooksResult });
                        // setLoadingWalkbooks("");
                        // setRoadSigns({ type: 'REPLACE', newRoadSigns: roadSignsResult });
                        // setLoadingRoadSigns("");
                        // setRecommendations({ type: 'REPLACE', newRecommendations: recommendationsResult });
                        // setLoadingRecommendations("");
                        // setVoterGroups({ type: 'REPLACE', newVoterGroups: voterGroupsResult });
                        // setLoadingVoterGroups("");
                        // setSurveys({ type: 'REPLACE', newSurveys: surveysResult });
                        // setLoadingSurveys("");
                        // setVoterTags({ type: 'REPLACE', newVoterTags: voterTagsResult });
                        // // setTextCampaigns({ type: 'REPLACE', newTextCampaigns: textCampaignsResult });
                        // // setLoadingTextCampaigns("");
                        setLoading("");

                    }
                    window.history.replaceState(null, null, '/');
                    // history.push('/turrim');
                    history.push('/turrim/dashboard');

                } else { // if (campaignUser.title === 'Staff' || campaignUser.title === 'Volunteer') {

                    // const walkbooksResult = await walkbookAPI.getAllUserWalkbooks(firebaseToken, userData.id, campaignUserData.campaign_id, signal);
                    // const roadSignsResult = await roadSignAPI.getAllRoadSigns(firebaseToken, campaignUserData.campaign_id, signal);
                    // const voterTagsResult = await voterTagsAPI.getAllTags(firebaseToken, signal);
                    // // const textCampaignsResult = await textCampaignAPI.getAllUserTextCampaigns(firebaseToken, userData.id, campaignUserData.campaign_id);

                    // Update the global state with the fetched data
                    if (isMounted.current) {
                        setCampaignUser(campaignUserData);
                        setUser(userData);
                        setCampaign(campaignResult);
                        setLoadingCampaign("");
                        // setWalkbooks({ type: 'REPLACE', newWalkbooks: walkbooksResult });
                        // setLoadingWalkbooks("");
                        // setRoadSigns({ type: 'REPLACE', newRoadSigns: roadSignsResult });
                        // setLoadingRoadSigns("");
                        // setVoterTags({ type: 'REPLACE', newVoterTags: voterTagsResult });
                        // // setTextCampaigns({ type: 'REPLACE', newTextCampaigns: textCampaignsResult });
                        // // setLoadingTextCampaigns("");
                        setLoading("");

                    }
                    window.history.replaceState(null, null, '/');
                    // history.push('/turrim');
                    history.push('/turrim/walkbooks');
                }

                // window.history.replaceState(null, null, '/');
                // history.push('/');
            }



        } catch (error) {
            console.error("Error loading campaign-specific data:", error);
            if (error.name === 'AbortError') {
                // Request was aborted, do not update state
            } else {
                if (isMounted.current) {
                    setLoading("");
                    setError("Failed to load campaign data. Please try again.");
                }
            }
        }

    };


    const handleInvitationSelect = async (selectedIndex) => {
        // console.log("Handling Invitation selection for index:", selectedIndex);
        if (!userResult || selectedIndex === null) return;

        const selectedInvitation = userResult.invitations[parseInt(selectedIndex, 10)];
        // console.log("invitation: ", invitation);
        if (!selectedInvitation) return;

        const updatedUserResult = {
            ...userResult,
            title: 'None',
            campaign_id: null,
            date_joined: null,
        };

        const campaignUserData = {
            invitation_title: selectedInvitation.offer_title,
            invitation_campaign_id: selectedInvitation.campaign_id,
            invitation_date_joined: null,
        };

        // Remove the invitations property as it's no longer needed
        // delete updatedUserResult.invitations;

        // setShowCampaignInvitationSelection(false);

        // console.log("Invitation selected: ", selectedInvitation);

        if (isMounted.current) {
            setLoading("Loading data...");
            // setCampaignUser(campaignUserData);
            setUser(updatedUserResult);
            setInvitation(selectedInvitation);
            setLoading("");
        }

        window.history.replaceState(null, null, '/');
        history.push('/turrim/invitation');
        // history.push('/turrim/campaign-invitation');

    };


    const [cardClasses, setCardClasses] = useState("card-hidden");


    useEffect(() => {
        const timer = setTimeout(() => {
            setCardClasses("");
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    // console.log("Rendering, userResult:", userResult);

    useEffect(() => {

        const fetchData = async () => {

            // Cancel any previous pending request
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }

            // Create a new AbortController for this request
            abortControllerRef.current = new AbortController();
            const signal = abortControllerRef.current.signal;

            try {
                const firebaseToken = await currentUser.getIdToken();
                const userData = await authAPI.getUser(firebaseToken, signal); // Pass signal here

                if (isMounted.current) {
                    setUserResult(userData);
                }
            } catch (error) {
                if (error.name === 'AbortError') {
                    // Request was aborted, do not update state
                } else {
                    console.error('Error fetching user data:', error);
                    if (isMounted.current) {
                        setError('Failed to fetch user data.');
                    }
                }
            }
        };



        if (currentUser) {
            // console.log('CampaignSelectPage: currentUser exists', currentUser);
            fetchData();

            // checkCampaignUser();
        }
        else {
            console.log('CampaignSelectPage: Redirecting to /');
            console.log('CampaignSelect: currentUser', currentUser);

            history.push('/'); // Redirect to login if no current user or token is expired or email is not verified
        }

        return () => {
            isMounted.current = false; // Set the flag to false when the component unmounts

            // Abort any ongoing requests
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };

    }, [history, currentUser]);

    return (
        <>
            <div className="content d-flex align-items-center p-0">
                <Container>


                    {((loading !== null) && (loading !== "")) ? (
                        // <LoadingSpinner />
                        // <PulsingLogo />
                        <div className="content d-flex align-items-center p-0">
                            <Container className="d-flex justify-content-center align-items-center">
                                <Row className="justify-content-center w-100">
                                    <Col className="mx-auto d-flex justify-content-center" lg={6} md={8}>
                                        <Card
                                            className="card-login"
                                            style={{ paddingBottom: '25px', paddingTop: '25px', minWidth: '350px' }}
                                        >
                                            <Card.Body
                                                style={{ paddingBottom: '15px', paddingTop: '15px' }}
                                            >
                                                <Row className="justify-content-center">
                                                    {/* <Spinner animation="border" /> */}
                                                    <PulsingLogo />
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    ) : (
                        userResult && (
                            <CampaignInvitationSelectionModal
                                campaigns={userResult?.campaigns}
                                onSelect={handleCampaignSelect}
                                invitations={userResult?.invitations}
                                onSelectInvitation={handleInvitationSelect}
                            />
                        )
                    )}
                </Container>
            </div>
        </>
    );
}

export default CampaignInvitationSelectPage;