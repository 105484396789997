class VoterTags {
    constructor() {
        this.baseURL = process.env.REACT_APP_API_BASE_URL;
    }

    getAllTags = async (token, signal) => {
        if (token) {
            const request = new Request(`${this.baseURL}/voter-tag/all/`, {
            method: 'GET',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${token}`},
            signal: signal
            });
            // Fetch request
            const response = await fetch(request);
            const data = await response.json();
            //console.log(data);
            return data;

        } else {
            console.error("No token found");
        }
    };
}

export default new VoterTags();