import React from "react";
import { Link, useLocation } from "react-router-dom";

// react-bootstrap components
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import { useUserContext } from "../../contexts/UserContext";

function AuthNavbar(props) {
  const {
    loading,
    setLoading,
    loadingCampaign,
    setLoadingCampaign,
    loadingWalkbooks,
    setLoadingWalkbooks,
    loadingRoadSigns,
    setLoadingRoadSigns,
    loadingRecommendations,
    setLoadingRecommendations,
    loadingVoterGroups,
    setLoadingVoterGroups
  } = useUserContext();
  const location = useLocation();

  return (
    <>
      <Navbar
        className="position-absolute w-100"
        expand="lg"
        variant="transparent"
      >
        <Container>
          <div className="navbar-wrapper">
            <Navbar.Brand
              href="https://www.turrim.io"
              onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior
                window.open('https://www.turrim.io', '_blank', 'noopener,noreferrer'); // Open in new tab
              }}
              rel="noopener noreferrer" // Security measure for links opening in a new tab
            >
              {/* <span className="d-none d-md-block">Turrim</span>
              <span className="d-block d-md-none">Turrim</span> */}
              <img
                // style={{ width: '55px' }}
                // src={require("../../assets/img/turrim/turrim-L-logo-apple.svg").default}
                style={{ width: '145px' }}
                src={require("../../assets/img/logo/turrim-labs-text.svg").default}
                alt="TRRM"
              />
            </Navbar.Brand>
          </div>
          <Nav navbar>
            <div className="justify-content-end navbar-wrapper">
              {((location.pathname === "/turrim/user-options") || (location.pathname === "/turrim/terms")) ? (
                (loading !== null && loading !== "") ? (
                  null
                ) : (
                  <Nav.Item
                    className="active mr-1"
                    style={{ color: 'black', backgroundColor: 'rgba(200, 200, 200, .9)', borderRadius: '5px' }}
                  >
                    <Nav.Link
                      as={Link}
                      to="/auth/login"
                      // disabled={loading !== null && loading !== ""}
                      onClick={props.logout}
                      style={{ color: 'black' }}
                    >
                      Sign out
                    </Nav.Link>
                  </Nav.Item>
                )
              ) : (
                <Nav.Item
                  className={
                    location.pathname === "/auth/login"
                      ? "active mr-1"
                      : "mr-1"
                  }
                  style={{ color: 'black', backgroundColor: 'rgba(200, 200, 200, .9)', borderRadius: '5px' }}
                >
                  <Nav.Link to="/auth/login" as={Link}>
                    <div 
                      className="d-flex justify-content-end align-items-center"
                      style={{ color: 'black' }}
                    >
                      <i className="nc-icon nc-mobile"></i>
                      Login
                    </div>
                  </Nav.Link>
                </Nav.Item>
              )}
            </div>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default AuthNavbar;