import React from 'react';
// import './ErrorMessage.css'; // Import the CSS file

// const ErrorMessage = ({ errorMessage }) => {
//   return (
//     <div className="error-container">
//       {errorMessage && <div className="error-message">{errorMessage}</div>}
//     </div>
//   );
// };

const ErrorMessage = ({ errorMessage, onClose }) => {
  return (
    <div className="error-container">
      {errorMessage && (
        <div className="error-message">
          <span>{errorMessage}</span>
          <button
            onClick={onClose}
            className="close-button"
            aria-label="Close error message"
          >
            &times;
          </button>
        </div>
      )}
    </div>
  );
};

export default ErrorMessage;
