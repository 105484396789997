import { useState, useEffect, useRef, useCallback } from 'react';
import { useUserContext } from "./UserContext";

import authAPI from '../apis/auth/auth';
import campaignAPI from '../apis/campaign/campaign';
import walkbookAPI from '../apis/walkbook/walkbook';
import roadSignAPI from '../apis/road_sign/road_sign';
import recommendedWalkbookAPI from '../apis/walkbook/recommended_walkbook';
import surveyAPI from '../apis/survey/survey';
import voterGroupAPI from '../apis/voter_group/voter_group';
import voterTagsAPI from '../apis/voter_tags/voter_tags';
import mailAPI from '../apis/mail/mail';
import textCampaignAPI from '../apis/text_campaign/text_campaign';
import phoneBankingCampaignAPI from '../apis/phone_banking/phone_banking';

import ErrorMessage from '../components/Errors/ErrorMessage';
import { set } from 'lodash';




const DataLoader = () => {
  const {
    setAuthenticated,
    setVerified,
    setLoading,
    currentUser,
    campaignUser,
    setUser,
    setInvitation,
    setCampaign,
    setWalkbooks,
    setRoadSigns,
    setRecommendations,
    setVoterGroups,
    setSurveys,
    setMailers,
    setTextCampaigns,
    setPhoneBankingCampaigns,
    setVoterTags,
    setLoadingCampaign,
    setLoadingWalkbooks,
    setLoadingRoadSigns,
    setLoadingRecommendations,
    setLoadingVoterGroups,
    setLoadingSurveys,
    setLoadingMailers,
    setLoadingTextCampaigns,
    setLoadingPhoneBankingCampaigns,
  } = useUserContext();

  const [errorMessage, setErrorMessage] = useState('');

  const clearErrorMessage = () => {
    setErrorMessage('');
  };

  const isMounted = useRef(false);

  const isValidData = (data, requiredProps) => {
    if (data === undefined) return false;
    for (const prop of requiredProps) {
      if (!(prop in data)) return false;
      const value = data[prop];
      if (value === undefined || (Array.isArray(value) && value.length === 0)) return false;
    }
    return true;
  };

  const safeSetState = (setStateFunction, data, requiredProps = []) => {
    if (isMounted.current && isValidData(data, requiredProps)) {
      setStateFunction(data);
    }
  };


  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);


  const currentCampaignId = campaignUser?.campaign_id;


  useEffect(() => {

    const abortController = new AbortController();
    const signal = abortController.signal;



    async function onLoad() {
      setErrorMessage('');

      if (isMounted.current && campaignUser?.campaign_id === currentCampaignId) {
        if (currentUser && !currentUser.stsTokenManager.isExpired && currentUser.emailVerified) {
          const firebaseToken = await currentUser.getIdToken();
          if (isMounted.current) {
            safeSetState(setAuthenticated, true);
            safeSetState(setVerified, true);
          }



          try {
            const serverUser = await authAPI.getUser(firebaseToken, signal);

            if (serverUser) {


              if (Object.keys(campaignUser).length > 0) {
                serverUser.campaign_id = campaignUser['campaign_id'] ? campaignUser['campaign_id'] : null;
                serverUser.title = campaignUser['title'] ? campaignUser['title'] : null;
                serverUser.date_joined = campaignUser['date_joined'] ? campaignUser['date_joined'] : null;
              }


              if (isMounted.current) {
                safeSetState(setLoading, "Reloading data...");
                safeSetState(setUser, serverUser);
              }

              if (serverUser.campaign_id) {

                if (isMounted.current) {
                  safeSetState(setLoadingCampaign, "Loading campaign...");
                  safeSetState(setLoadingWalkbooks, "Loading walkbooks...");
                  safeSetState(setLoadingRoadSigns, "Loading road signs...");
                  safeSetState(setLoadingRecommendations, "Loading recommendations...");
                  safeSetState(setLoadingVoterGroups, "Loading voter groups...");
                  safeSetState(setLoadingSurveys, "Loading surveys...");
                  safeSetState(setLoadingMailers, "Loading mail campaigns...");
                  // safeSetState(setLoadingTextCampaigns, "Loading text campaigns...");
                  // safeSetState(setLoadingPhoneBankingCampaigns, "Loading phone bankings...");
                }



                const serverCampaign = await campaignAPI.getCampaign(firebaseToken, serverUser.campaign_id, signal);
                if (isMounted.current) {
                  safeSetState(setCampaign, serverCampaign);
                  safeSetState(setLoadingCampaign, "");
                }

                if (serverUser.title === 'Manager' || serverUser.title === 'Director') {
                  const serverWalkbooks = await walkbookAPI.getAllWalkbooks(firebaseToken, serverUser.campaign_id, signal);
                  if (isMounted.current) {
                    safeSetState(setWalkbooks, { type: 'REPLACE', newWalkbooks: serverWalkbooks }, ['newWalkbooks']);
                    safeSetState(setLoadingWalkbooks, "");

                  }

                  const serverRoadSigns = await roadSignAPI.getAllRoadSigns(firebaseToken, serverUser.campaign_id, signal);
                  if (isMounted.current) {
                    safeSetState(setRoadSigns, { type: 'REPLACE', newRoadSigns: serverRoadSigns }, ['newRoadSigns']);
                    safeSetState(setLoadingRoadSigns, "");
                  }

                  const serverRecommendations = await recommendedWalkbookAPI.getAllRecommendations(firebaseToken, serverUser.campaign_id, signal);
                  if (isMounted.current) {
                    safeSetState(setRecommendations, { type: 'REPLACE', newRecommendations: serverRecommendations }, ['newRecommendations']);
                    safeSetState(setLoadingRecommendations, "");
                  }

                  const serverVoterGroups = await voterGroupAPI.getAllVoterGroups(firebaseToken, serverUser.campaign_id, signal);
                  if (isMounted.current) {
                    safeSetState(setVoterGroups, { type: 'REPLACE', newVoterGroups: serverVoterGroups }, ['newVoterGroups']);
                    safeSetState(setLoadingVoterGroups, "");
                  }

                  const serverSurveys = await surveyAPI.getAllSurveys(firebaseToken, serverUser.campaign_id, signal);
                  if (isMounted.current) {
                    safeSetState(setSurveys, { type: 'REPLACE', newSurveys: serverSurveys }, ['newSurveys']);
                    safeSetState(setLoadingSurveys, "");
                  }

                  const serverMailers = await mailAPI.getAllMailers(firebaseToken, serverUser.campaign_id, signal);
                  if (isMounted.current) {
                    safeSetState(setMailers, { type: 'REPLACE', newMailers: serverMailers }, ['newMailers']);
                    safeSetState(setLoadingMailers, "");
                  }

                  const serverVoterTags = await voterTagsAPI.getAllTags(firebaseToken, signal);
                  if (isMounted.current) {
                    safeSetState(setVoterTags, { type: 'REPLACE', newVoterTags: serverVoterTags }, ['newVoterTags']);

                  }

                  // const serverTextCampaigns = await textCampaignAPI.getAllTextCampaigns(firebaseToken, serverUser.campaign_id, signal);
                  // if (isMounted.current) {
                  //   safeSetState(setTextCampaigns, { type: 'REPLACE', newTextCampaigns: serverTextCampaigns }, ['newTextCampaigns']);
                  //   safeSetState(setLoadingTextCampaigns, "");
                  // }

                  // const serverPhoneBankingCampaigns = await phoneBankingCampaignAPI.getAllPhoneBankingCampaigns(firebaseToken, serverUser.campaign_id, signal);
                  // if (isMounted.current) {
                  //   safeSetState(setPhoneBankingCampaigns, { type: 'REPLACE', newPhoneBankingCampaigns: serverPhoneBankingCampaigns }, ['newPhoneBankingCampaigns']);
                  //   safeSetState(setLoadingPhoneBankingCampaigns, "");
                  // }

                }

                else { //if (serverUser.title === 'Staff' || serverUser.title === 'Volunteer') {
                  const serverWalkbooks = await walkbookAPI.getAllUserWalkbooks(firebaseToken, serverUser.id, serverUser.campaign_id, signal);
                  if (isMounted.current) {
                    safeSetState(setWalkbooks, { type: 'REPLACE', newWalkbooks: serverWalkbooks }, ['newWalkbooks']);
                    safeSetState(setLoadingWalkbooks, "");

                  }

                  const serverRoadSigns = await roadSignAPI.getAllRoadSigns(firebaseToken, serverUser.campaign_id, signal);
                  if (isMounted.current) {
                    safeSetState(setRoadSigns, { type: 'REPLACE', newRoadSigns: serverRoadSigns }, ['newRoadSigns']);
                    safeSetState(setLoadingRoadSigns, "");
                  }

                  const serverVoterTags = await voterTagsAPI.getAllTags(firebaseToken, signal);
                  if (isMounted.current) {
                    safeSetState(setVoterTags, { type: 'REPLACE', newVoterTags: serverVoterTags }, ['newVoterTags']);

                  }



                  // const serverTextCampaigns = await textCampaignAPI.getAllUserTextCampaigns(firebaseToken, serverUser.id, serverUser.campaign_id, signal);
                  // if (isMounted.current) {
                  //   safeSetState(setTextCampaigns, { type: 'REPLACE', newTextCampaigns: serverTextCampaigns }, ['newTextCampaigns']);
                  //   safeSetState(setLoadingTextCampaigns, "");
                  // }

                  // const serverPhoneBankingCampaigns = await phoneBankingCampaignAPI.getAllUserPhoneBankingCampaigns(firebaseToken, serverUser.id, serverUser.campaign_id, signal);
                  // if (isMounted.current) {
                  //   safeSetState(setPhoneBankingCampaigns, { type: 'REPLACE', newPhoneBankingCampaigns: serverPhoneBankingCampaigns }, ['newPhoneBankingCampaigns']);
                  //   safeSetState(setLoadingPhoneBankingCampaigns, "");
                  // }

                }

                if (isMounted.current) {
                  safeSetState(setInvitation, null);
                  safeSetState(setLoadingCampaign, "");
                  safeSetState(setLoadingWalkbooks, "");
                  safeSetState(setLoadingRoadSigns, "");
                  safeSetState(setLoadingRecommendations, "");
                  safeSetState(setLoadingVoterGroups, "");
                  safeSetState(setLoadingSurveys, "");
                  safeSetState(setLoadingMailers, "");
                }

              }

              // else {
              //   const serverInvitation = await invitationAPI.getInvitation(firebaseToken, serverUser.email, signal);
              //   if (isMounted.current) {
              //     safeSetState(setInvitation, serverInvitation);
              //     // safeSetState(setLoadingCampaign, "");
              //     // safeSetState(setLoadingWalkbooks, "");
              //     // safeSetState(setLoadingRoadSigns, "");
              //     // safeSetState(setLoadingRecommendations, "");
              //     // safeSetState(setLoadingVoterGroups, "");

              //     // safeSetState(setLoading, "");
              //   }
              // }

              if (isMounted.current) {
                safeSetState(setLoading, "");
              }

            }

          } catch (error) {
            if (error.name === 'AbortError') {
              // Fetch was aborted, no need to handle error
            } else {
              if (isMounted.current) {
                setErrorMessage('The server is currently unavailable. Please try again later.');
              }
            }
          }


        } else {
          if (isMounted.current) {
            console.log("currentUser not found");
            safeSetState(setAuthenticated, false);
            safeSetState(setVerified, false);
          }
        }
      } else {
        if (isMounted.current) {
          console.log("campaignUser not found");
          safeSetState(setAuthenticated, false);
          safeSetState(setVerified, false);
        }
      }
    }


    if (currentUser) {
      onLoad();
    }

    return () => {
      // This function runs when the component unmounts
      abortController.abort(); // Cancel any ongoing fetches
    };

  }, [currentUser, campaignUser, currentCampaignId, setAuthenticated, setVerified, setUser, setInvitation, setCampaign, setWalkbooks, setRoadSigns, setRecommendations, setVoterGroups, setSurveys, setMailers, setVoterTags, setTextCampaigns, setPhoneBankingCampaigns, setLoading, setLoadingCampaign, setLoadingWalkbooks, setLoadingRoadSigns, setLoadingRecommendations, setLoadingVoterGroups, setLoadingSurveys, setLoadingMailers, setLoadingTextCampaigns, setLoadingPhoneBankingCampaigns]);


  return (
    <div>
      {errorMessage && <ErrorMessage errorMessage={errorMessage} onClose={clearErrorMessage} />}
    </div>
  );
};

export default DataLoader;
