import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useUserContext } from '../../contexts/UserContext';
import TurrimLayout from '../../layouts/TurrimLayout.js';
import { set } from 'lodash';


const clearLocalStorage = () => {
  // const items = ['invitation', 'walkbooks', 'roadSigns', 'recommendations', 'voterGroups', 'campaign', 'campaignUser', 'user', 'firebase_token'];
  const items = ['invitation', 'campaignUser'];
  items.forEach(item => localStorage.removeItem(item));
};


const ProtectedRoute = ({ component: Component, ...rest }) => {
  // const {
  //   currentUser,
  //   authenticating,
  // } = useUserContext();

  const {
    campaignUser,
    setCampaignUser,
    authenticating,
    setAuthenticating,
    user,
    setUser,
    currentUser,
    setCurrentUser,
    loading,
    setLoading,
    setAuthenticated,
    verified,
    setVerified,
    invitation,
    setInvitation,
    campaign,
    setCampaign,
    setWalkbooks,
    setRoadSigns,
    setRecommendations,
    setVoterGroups,
    setSurveys,
    setTextCampaigns,
    phoneBankingCampaigns,
    setPhoneBankingCampaigns,
    loadingCampaign,
    setLoadingCampaign,
    loadingWalkbooks,
    setLoadingWalkbooks,
    loadingRoadSigns,
    setLoadingRoadSigns,
    loadingRecommendations,
    setLoadingRecommendations,
    loadingVoterGroups,
    setLoadingVoterGroups,
    setLoadingSurveys,
    setLoadingTextCampaigns,
    setLoadingPhoneBankingCampaigns,
    setVoterTags
  } = useUserContext();

  const clearData = () => {
    setAuthenticating(true);
    setAuthenticated(false);
    setVerified(false);

    setUser({});
    setCampaignUser(null);
    setCampaign({});
    setInvitation(null);
    setCurrentUser(null);

    setWalkbooks({ type: 'REPLACE', newWalkbooks: [] });
    setRoadSigns({ type: 'REPLACE', newRoadSigns: [] });
    setRecommendations({ type: 'REPLACE', newRecommendations: [] });
    setVoterGroups({ type: 'REPLACE', newVoterGroups: [] });
    setTextCampaigns({ type: 'REPLACE', newTextCampaigns: [] });
    setPhoneBankingCampaigns({ type: 'REPLACE', newPhoneBankingCampaigns: [] });
    setVoterTags({ type: 'REPLACE', newVoterTags: [] });
    setSurveys({ type: 'REPLACE', newSurveys: [] });

    setLoading("")
    setLoadingCampaign("");
    setLoadingWalkbooks("");
    setLoadingRoadSigns("");
    setLoadingRecommendations("");
    setLoadingVoterGroups("");
    setLoadingSurveys("");
    setLoadingTextCampaigns("");
    setLoadingPhoneBankingCampaigns("");

    clearLocalStorage();
    localStorage.clear();
  };

  useEffect(() => {
    if (!authenticating && !currentUser) {
      clearData();
    }
  }, [authenticating, currentUser]);


  if (authenticating) {

    return null;
  }

  // console.log("currentUser: ", currentUser);


  if (!currentUser) {

    // clearData();

    // console.log("Redirecting to login");

    return <Redirect to='/auth/login' />;

  }

  if (currentUser && !currentUser.emailVerified) {
    return <Redirect to='/auth/verify' />;
  }

  // Render the component only when the user is signed in and authentication status is confirmed
  return (
    <Route {...rest} render={(props) => <TurrimLayout {...props} />} />
  );
};

export default ProtectedRoute;
