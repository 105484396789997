class PhoneBanking {

    constructor() {
        this.baseURL = process.env.REACT_APP_API_BASE_URL;
    }


    getAvailableNumbers = async (token, campaignId, userId, areaCode, zipCode) => {
        let request;

        if (token) {

            // const params = new URLSearchParams({
            //     campaign_id: campaignId,
            // });
            // if (areaCode.trim()) params.append('area_code', areaCode.trim());
            // if (zipCode.trim()) params.append('zip_code', zipCode.trim());

            // Create request
            // const request = new Request(`${this.baseURL}/phone-banking/available-numbers/?${params.toString()}`, {

            if (areaCode.trim() === '' && zipCode.trim() === '') {
                throw new Error('Area code or zip code is required');
            }

            const areaCodeTrue = areaCode.trim() !== '';
            const zipCodeTrue = zipCode.trim() !== '';

            if (areaCodeTrue && zipCodeTrue) {
                request = new Request(`${this.baseURL}/phone-banking/available-numbers/?campaign_id=${campaignId}&area_code=${areaCode}&zip_code=${zipCode}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Authorization': `Bearer ${token}` }
                });

            } else if (areaCodeTrue) {
                request = new Request(`${this.baseURL}/phone-banking/available-numbers/?campaign_id=${campaignId}&area_code=${areaCode}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Authorization': `Bearer ${token}` }
                });

            } else { //else if (zipCodeTrue) {
                request = new Request(`${this.baseURL}/phone-banking/available-numbers/?campaign_id=${campaignId}&zip_code=${zipCode}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Authorization': `Bearer ${token}` }
                });
            }

            // Fetch request
            const response = await fetch(request);
            if (response.status === 500) {
                throw new Error('Internal server error');
                // return null;
            }
            // Extracting response data
            const data = await response.json();

            if (response.status >= 400 && response.status < 500) {
                throw data;
            }

            return data;

        } else {
            console.error("No token found");
        }
    };



    purchaseNumber = async (token, campaignId, userId, phoneNumber) => {

        const formData = {
            "campaign_id": campaignId,
            "user_id": userId,
            "phone_number": phoneNumber,
        };

        if (token) {
            // Create request
            const request = new Request(`${this.baseURL}/phone-banking/purchase-number`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-client_type': 'web'
                },
                body: JSON.stringify(formData),
                credentials: 'include',
            });
            // Fetch request
            const response = await fetch(request);
            const data = await response.json();

            if (response.status >= 400 && response.status < 500) {
                throw data;
            }

            return data;

        }
        else {
            console.error("No token found");
        }
    };



    setupPhoneBankingAccount = async (token, campaignId, userId) => {
        const formData = {
            "campaign_id": campaignId,
            "user_id": userId,
        };

        if (token) {
            // const request = new Request(`${this.baseURL}/text-campaign/register/?campaign_id=` + campaignId + `&user_id=` + userId, {
            const request = new Request(`${this.baseURL}/phone-banking/setup-account`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-client_type': 'web'
                },
                body: JSON.stringify(formData),
                credentials: 'include',
            });

            // Fetch request
            const response = await fetch(request);
            // 500 error handling
            if (response.status === 500) {
                throw new Error('Internal server error');
            }
            // 400 error handling
            const data = await response.json();
            if (response.status >= 400 && response.status < 500) {
                if (data.detail) {
                    throw data.detail;
                }
                throw data;
            }
            return data;
        } else {
            console.error("No token found");
        }
    };



    getPhoneBankingAccountStatus = async (token, campaignId) => {

        if (token) {
            if (campaignId) {
                // Create request
                const request = new Request(`${this.baseURL}/phone-banking/account-status/?campaign_id=${campaignId}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                // Fetch request
                const response = await fetch(request);
                const data = await response.json();

                if (response.status >= 400 && response.status < 500) {
                    throw data;
                }

                return data;

            }
            else {
                throw new Error('ID was not provided');
            }
        } else {
            console.error("No token found");
        }
    };



    createPhoneBankingCampaign = async (token, campaign_id, owner_id, user_ids, name, description, script, filterParams) => {
        const formData = {
            "name": name,
            "creation_method": "Created",
            "status": "Draft",
            "description": description,
            "script": script,
            "filter_params": filterParams,
            "campaign_id": campaign_id,
            "owner_id": owner_id,
            "user_ids": user_ids,
        };

        if (token) {
            const request = new Request(`${this.baseURL}/phone-banking/`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-client_type': 'web'
                },
                body: JSON.stringify(formData),
                credentials: 'include',
            });

            const response = await fetch(request);
            if (response.status === 500) {
                throw new Error('Internal server error');
            }
            const data = await response.json();
            if (response.status >= 400 && response.status < 500) {
                if (data.detail) {
                    throw data.detail;
                }
                throw data;
            }
            return data;
        } else {
            console.error("No token found");
        }
    };



    getPhoneBankingCampaign = async (token, id, campaign_id) => {
        if (token) {
            if (id) {
                const request = new Request(`${this.baseURL}/phone-banking/?id=${id}&campaign_id=${campaign_id}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                const response = await fetch(request);
                const data = await response.json();
                return data;
            } else {
                throw new Error('ID was not provided');
            }
        } else {
            console.error("No token found");
        }
    };



    getMinimalPhoneBankingCampaign = async (token, id, campaign_id) => {
        //const token = localStorage.getItem('token');
        // const token = localStorage.getItem('firebase_token');

        if (token) {
            if (id && campaign_id) {
                // Create request
                const request = new Request(`${this.baseURL}/phone-banking/minimal/?id=${id}&campaign_id=${campaign_id}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'X-client_type': 'web'
                    }
                    //headers: {'Authorization': `Bearer ${token}`}
                });
                // Fetch request
                const response = await fetch(request);
                const data = await response.json();
                //console.log(data);
                return data;
            }
            else {
                throw new Error('ID was not provided');
            }
        } else {
            console.error("No token found");
        }
    };



    initiateCall = async (token, phoneBankingCampaignId, campaignId, userId, voterId, volunteerPhoneNumber) => {
        if (token) {
            const request = new Request(`${this.baseURL}/phone-banking/${phoneBankingCampaignId}/initiate-call`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    campaign_id: campaignId,
                    user_id: userId,
                    voter_id: voterId,
                    volunteer_phone: volunteerPhoneNumber
                })
            });

            try {
                const response = await fetch(request);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                return data;
            } catch (error) {
                console.error("Error initiating call:", error);
                throw error;
            }
        } else {
            console.error("No token found");
            throw new Error("No token found");
        }
    };



    acquireNumber = async (token, campaign_id, user_id) => {

        const formData = {
            "campaign_id": campaign_id,
            "user_id": user_id
        };

        if (token) {
            const request = new Request(`${this.baseURL}/phone-banking/call/acquire-number`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-client_type': 'web'
                },
                body: JSON.stringify(formData),
                credentials: 'include',
            });

            try {
                const response = await fetch(request);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                return data;
            } catch (error) {
                console.error("Error acquiring number to make calls:", error);
                throw error;
            }
        } else {
            console.error("No token found");
            throw new Error("No token found");
        }
    };



    createCallSession = async (token, campaign_id, user_id, phone_number) => {

        const formData = {
            "campaign_id": campaign_id,
            "user_id": user_id,
            "phone_number": phone_number,
        };

        if (token) {
            const request = new Request(`${this.baseURL}/phone-banking/call/create-session`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-client_type': 'web'
                },
                // body: JSON.stringify({ campaign_id, user_id, phone_number }),
                body: JSON.stringify(formData),
                credentials: 'include',
            });

            try {
                const response = await fetch(request);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                return data;
            } catch (error) {
                console.error("Error creating call session:", error);
                throw error;
            }
        } else {
            console.error("No token found");
            throw new Error("No token found");
        }
    };



    endCallSession = async (token, campaign_id, user_id, session_id) => {

        const formData = {
            "campaign_id": campaign_id,
            "user_id": user_id,
            "call_session_id": session_id,
        };

        if (token) {
            const request = new Request(`${this.baseURL}/phone-banking/call/end-session`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-client_type': 'web'
                },
                // body: JSON.stringify({ campaign_id, user_id, session_id }),
                body: JSON.stringify(formData),
                credentials: 'include',
            });

            try {
                const response = await fetch(request);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                return data;
            } catch (error) {
                console.error("Error ending call session:", error);
                throw error;
            }
        } else {
            console.error("No token found");
            throw new Error("No token found");
        }
    };



    releaseNumber = async (token, campaign_id, user_id, phone_number) => {

        const formData = {
            "campaign_id": campaign_id,
            "user_id": user_id,
            "phone_number": phone_number,
        };

        if (token) {
            const request = new Request(`${this.baseURL}/phone-banking/call/release-number`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-client_type': 'web'
                },
                body: JSON.stringify(formData),
                credentials: 'include',
            });

            try {
                const response = await fetch(request);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                return data;
            } catch (error) {
                console.error("Error releasing the currently acquired number for making calls:", error);
                throw error;
            }
        } else {
            console.error("No token found");
            throw new Error("No token found");
        }
    };


    releaseUncalledVoter = async (token, phoneBankingCampaignId, campaignId, voterId, userId) => {
        if (token) {
            const request = new Request(`${this.baseURL}/phone-banking/release-uncalled-voter`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    campaign_id: campaignId,
                    phone_banking_campaign_id: phoneBankingCampaignId,
                    voter_id: voterId,
                    user_id: userId
                })
            });

            try {
                const response = await fetch(request);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                return data;
            } catch (error) {
                console.error("Error releasing uncalled voter:", error);
                throw error;
            }
        } else {
            console.error("No token found");
            throw new Error("No token found");
        }
    };



    getPhoneBankingToken = async (token, campaign_id, user_id) => {
        if (token) {
            if (campaign_id && user_id) {
                const request = new Request(`${this.baseURL}/phone-banking/access-token/?campaign_id=${campaign_id}&user_id=${user_id}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                const response = await fetch(request);
                const data = await response.json();
                return data;
            } else {
                throw new Error('IDs were not provided');
            }
        } else {
            console.error("No token found");
        }
    };


    getNextRecipient = async (token, phoneBankingCampaignId, campaignId, userId) => {
        if (token) {
            const request = new Request(`${this.baseURL}/phone-banking/${phoneBankingCampaignId}/next-voter?campaign_id=${campaignId}&user_id=${userId}`, {
                method: 'GET',
                credentials: 'include',
                headers: { 'Authorization': `Bearer ${token}` }
            });

            try {
                const response = await fetch(request);
                if (!response.ok) {
                    if (response.status === 404) {
                        return null; // No more voters available
                    }
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                return data;
            } catch (error) {
                console.error("Error fetching next recipient:", error);
                throw error;
            }
        } else {
            console.error("No token found");
            throw new Error("No token found");
        }
    };


    getUpdatedRecipients = async (token, phoneBankingCampaignId, campaignId, lastUpdateTime) => {
        if (token) {
            if (!lastUpdateTime) {
                const tenSecondsAgo = new Date(Date.now() - 20 * 1000);
                lastUpdateTime = tenSecondsAgo;
            }

            const request = new Request(`${this.baseURL}/phone-banking/updated-recipients/?id=${phoneBankingCampaignId}&campaign_id=${campaignId}&last_update_time=${lastUpdateTime.toISOString()}`, {
                method: 'GET',
                credentials: 'include',
                headers: { 'Authorization': `Bearer ${token}` }
            });

            try {
                const response = await fetch(request);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                return data;
            } catch (error) {
                console.error("Error fetching updated voters:", error);
                throw error;
            }
        } else {
            console.error("No token found");
            throw new Error("No token found");
        }
    };



    getAllPhoneBankingCampaigns = async (token, campaign_id, signal) => {
        if (token) {
            if (campaign_id) {
                const request = new Request(`${this.baseURL}/phone-banking/all/?campaign_id=${campaign_id}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Authorization': `Bearer ${token}` },
                    signal: signal
                });
                const response = await fetch(request);
                const data = await response.json();
                return data;
            } else {
                throw new Error('Campaign ID was not provided');
            }
        } else {
            console.error("No token found");
        }
    };



    getAllUserPhoneBankingCampaigns = async (token, user_id, campaign_id, signal) => {
        if (token) {
            if (user_id && campaign_id) {
                const request = new Request(`${this.baseURL}/phone-banking/all-user/?user_id=${user_id}&campaign_id=${campaign_id}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'X-client_type': 'web'
                    },
                    signal: signal
                });
                const response = await fetch(request);
                const data = await response.json();
                return data;
            } else {
                throw new Error('User ID and/or Campaign ID was not provided');
            }
        } else {
            console.error("No token found");
        }
    };


    updateCallResult = async (token, phoneBankingCampaignId, campaignId, userId, voterId, callResult, callNotes, tags, callDuration, callStartTime, callSessionId) => {
        if (token) {
            const request = new Request(`${this.baseURL}/phone-banking/${phoneBankingCampaignId}/update-call-result`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    campaign_id: campaignId,
                    user_id: userId,
                    voter_id: voterId,
                    call_result: callResult,
                    call_notes: callNotes,
                    tags: tags,
                    call_duration: callDuration,
                    call_start_time: callStartTime,
                    call_session_id: callSessionId
                })
            });

            try {
                const response = await fetch(request);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                return data;
            } catch (error) {
                console.error("Error updating call result:", error);
                throw error;
            }
        } else {
            console.error("No token found");
            throw new Error("No token found");
        }
    };



    updatePhoneBankingCampaign = async (token, id, name, description, status, campaign_id, user_id) => {
        const formData = {
            "id": id,
            "name": name,
            "description": description,
            "status": status,
            "campaign_id": campaign_id,
            "user_id": user_id,
        };

        if (token) {
            if (id) {
                const request = new Request(`${this.baseURL}/phone-banking/`, {
                    method: 'PUT',
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'X-client_type': 'web'
                    },
                    body: JSON.stringify(formData),
                    credentials: 'include',
                });
                const response = await fetch(request);
                const data = await response.json();
                return data;
            } else {
                throw new Error('ID or Name was not provided');
            }
        } else {
            console.error("No token found");
        }
    };
}

export default new PhoneBanking();