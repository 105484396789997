class TextCampaign {

  constructor() {
    this.baseURL = process.env.REACT_APP_API_BASE_URL;
  }

  registerTextCampaign = async (token, campaignData, campaignId, userId) => {
    // try {
    //   const response = await axios.post('/api/text-campaign/register', campaignData);
    //   return response.data;
    // } catch (error) {
    //   console.error('Error registering text campaign:', error);
    //   throw error;
    // }

    const formData = {
      "friendly_name": campaignData.friendlyName,
      "company_name": campaignData.companyName,
      // "ein": campaignData.ein,
      "address": campaignData.address,
      "city": campaignData.city,
      "state": campaignData.state,
      "zip": campaignData.zip,
      "contact_first_name": campaignData.contactFirstName,
      "contact_last_name": campaignData.contactLastName,
      "contact_email": campaignData.contactEmail,
      "contact_phone": campaignData.contactPhone,
      "website_url": campaignData.websiteUrl,
      "campaign_verify_token": campaignData.campaignVerifyToken,
      // "use_case_description": campaignData.useCaseDescription,
      // businessIdentity: 'direct_customer',
      // businessType: 'Non-profit Corporation',
      // businessIndustry: 'NOT_FOR_PROFIT',
      // businessRegistrationIdType: 'EIN',
      // businessRegistrationNumber: ''
      "business_identity": campaignData.businessIdentity,
      "business_type": campaignData.businessType,
      "business_industry": campaignData.businessIndustry,
      "business_registration_id_type": campaignData.businessRegistrationIdType,
      "business_registration_number": campaignData.businessRegistrationNumber,
      "job_position": campaignData.jobPosition,
      "business_title": campaignData.businessTitle,
      "campaign_id": campaignId,
      "user_id": userId,
    };

    if (token) {
      // const request = new Request(`${this.baseURL}/text-campaign/register/?campaign_id=` + campaignId + `&user_id=` + userId, {
      const request = new Request(`${this.baseURL}/text-campaign/register`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        body: JSON.stringify(formData),
        credentials: 'include',
      });

      // Fetch request
      const response = await fetch(request);
      // 500 error handling
      if (response.status === 500) {
        throw new Error('Internal server error');
      }
      // 400 error handling
      const data = await response.json();
      if (response.status >= 400 && response.status < 500) {
        if (data.detail) {
          throw data.detail;
        }
        throw data;
      }
      return data;
    } else {
      console.error("No token found");
    }
  };



  getCampaignStatus = async (token, campaignId) => {

    if (token) {
      if (campaignId) {
        // Create request
        const request = new Request(`${this.baseURL}/text-campaign/twilio/${campaignId}/status`, {
          method: 'GET',
          credentials: 'include',
          headers: { 'Authorization': `Bearer ${token}` }
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();

        if (response.status >= 400 && response.status < 500) {
          throw data;
        }

        return data.status;

      }
      else {
        throw new Error('ID was not provided');
      }
    } else {
      console.error("No token found");
    }
  };


  createA2PCampaign = async (token, campaignData, campaignId, userId) => {

    const formData = {
      "description": campaignData.description,
      "sample_messages": campaignData.sampleMessages,
      "use_case": campaignData.useCase,
      "has_embedded_links": campaignData.messageContents.embeddedLinks,
      "has_phone_numbers": campaignData.messageContents.phoneNumbers,
      "consent_description": campaignData.consentDescription,
      "opt_in_keywords": campaignData.optInKeywords,
      "opt_in_message": campaignData.optInMessage,
      "campaign_id": campaignId,
      "user_id": userId,
    };

    if (token) {
      const request = new Request(`${this.baseURL}/text-campaign/create-a2p-campaign`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        body: JSON.stringify(formData),
        // body: JSON.stringify({ campaign_id: campaignId, ...campaignData }),
        credentials: 'include',
      });

      const response = await fetch(request);
      const data = await response.json();
      
      if (response.status >= 400 && response.status < 500) {
        throw data;
      }
      return data;
    } else {
      console.error("No token found");
    }
  };


  createTextCampaign = async (token, campaign_id, user_id, name, description, message, filterParams) => {
    const formData = {
      "name": name,
      "creation_method": "Created",
      "status": "Draft",
      "description": description,
      "message": message,
      "filter_params": filterParams,
      "campaign_id": campaign_id,
      "user_id": user_id,
    };

    console.log(formData);

    if (token) {
      const request = new Request(`${this.baseURL}/text-campaign/`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        body: JSON.stringify(formData),
        credentials: 'include',
      });

      // Fetch request
      const response = await fetch(request);
      // 500 error handling
      if (response.status === 500) {
        throw new Error('Internal server error');
      }
      // 400 error handling
      const data = await response.json();
      if (response.status >= 400 && response.status < 500) {
        if (data.detail) {
          throw data.detail;
        }
        throw data;
      }
      return data;
    } else {
      console.error("No token found");
    }
  };


  getTextCampaign = async (token, id, campaign_id) => {
    // const token = localStorage.getItem('firebase_token');
    // console.log("Getting text campaign");

    if (token) {
      if (id) {
        // Create request
        const request = new Request(`${this.baseURL}/text-campaign/?text_campaign_id=` + id + `&campaign_id=` + campaign_id, {
          method: 'GET',
          credentials: 'include',
          headers: { 'Authorization': `Bearer ${token}` }
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        // console.log(data);
        return data;
      }
      else {
        throw new Error('ID was not provided');
      }
    } else {
      console.error("No token found");
    }
  };

  
  getMinimalTextCampaign = async (token, id, campaign_id) => {

    if (token) {
      if (id) {


        const request = new Request(`${this.baseURL}/text-campaign/minimal/?text_campaign_id=` + id + `&campaign_id=` + campaign_id, {
          method: 'GET',
          credentials: 'include',
          headers: { 'Authorization': `Bearer ${token}` }
        });

        const response = await fetch(request);
        const data = await response.json();
        return data;
      }
      else {
        throw new Error('ID was not provided');
      }
    } else {
      console.error("No token found");
    }
  };


  sendMessage = async (token, textCampaignId, campaignId, userId, voterId, sendingPhoneNumber, message) => {
    // console.log("Sending message");

    if (token) {
      const request = new Request(`${this.baseURL}/text-campaign/${textCampaignId}/send`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          campaign_id: campaignId,
          user_id: userId,
          voter_id: voterId,
          sending_phone_number: sendingPhoneNumber,
          message: message
        })
      });

      try {
        const response = await fetch(request);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // console.log(data);
        return data;
      } catch (error) {
        console.error("Error sending message:", error);
        throw error;
      }
    } else {
      console.error("No token found");
      throw new Error("No token found");
    }
  };


  getUpdatedRecipients = async (token, textCampaignId, campaignId, lastUpdateTime) => {
    if (token) {
      // const url = new URL(`${this.baseURL}/text-campaign/${textCampaignId}/updated-recipients`);
      // url.searchParams.append('campaign_id', campaignId);
      // if (lastUpdateTime) {
      //   url.searchParams.append('last_update_time', lastUpdateTime.toISOString());
      // }

      // const request = new Request(url, {
      //   method: 'GET',
      //   headers: {
      //     'Authorization': `Bearer ${token}`,
      //     'Content-Type': 'application/json'
      //   }
      // });

      // console.log("Getting updated recipients");
      // console.log("api lastUpdateTime: ", lastUpdateTime);

      if (!lastUpdateTime) {
        const tenSecondsAgo = new Date(Date.now() - 20 * 1000);
        lastUpdateTime = tenSecondsAgo;
        // console.log("api tenSecondsAgo: ", tenSecondsAgo);
      }


      // const request = new Request(`${this.baseURL}/text-campaign/${textCampaignId}/updated-recipients/?text_campaign_id=`+textCampaignId+`&campaign_id=`+campaignId+`&last_update_time=`+lastUpdateTime.toISOString(), {
      const request = new Request(`${this.baseURL}/text-campaign/updated-recipients/?text_campaign_id=` + textCampaignId + `&campaign_id=` + campaignId + `&last_update_time=` + lastUpdateTime.toISOString(), {
        method: 'GET',
        credentials: 'include',
        headers: { 'Authorization': `Bearer ${token}` }
      });

      try {
        const response = await fetch(request);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // console.log(data);
        return data;
      } catch (error) {
        console.error("Error fetching updated recipients:", error);
        throw error;
      }
    } else {
      console.error("No token found");
      throw new Error("No token found");
    }
  };



  // getTextCampaignVoters = async (token, id, skip, limit) => {
  //   // console.log("In api call");
  //   // console.log("id: ", id);
  //   // console.log("skip: ", skip);
  //   // console.log("limit: ", limit);
  //   // const token = localStorage.getItem('firebase_token');

  //   if (token) {
  //     // if (id && skip && limit) {
  //     if (id !== undefined && id !== null && skip !== undefined && skip !== null && limit !== undefined && limit !== null) {
  //       // Create request
  //       const request = new Request(`${this.baseURL}/text-campaign/voters/?group_id=` + id + '&skip=' + skip + '&limit=' + limit, {
  //         method: 'GET',
  //         credentials: 'include',
  //         headers: { 'Authorization': `Bearer ${token}` }
  //       });
  //       // Fetch request
  //       const response = await fetch(request);
  //       const data = await response.json();
  //       //console.log(data);
  //       return data;
  //     }
  //     else {
  //       throw new Error('One or more parameters were not provided');
  //       // throw new Error('ID was not provided');
  //     }
  //   } else {
  //     console.error("No token found");
  //   }
  // };


  getAllTextCampaigns = async (token, campaign_id, signal) => {
    // const token = localStorage.getItem('firebase_token');
    if (token) {
      if (campaign_id) {
        // Create request
        const request = new Request(`${this.baseURL}/text-campaign/all/?campaign_id=` + campaign_id, {
          method: 'GET',
          credentials: 'include',
          headers: { 'Authorization': `Bearer ${token}` },
          signal: signal
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        //console.log(data);
        return data;
      }
      else {
        throw new Error('ID or Name was not provided');
      }
    } else {
      console.error("No token found");
    }
  };


  // getAllTextCampaignTouchesForWeek = async (token, campaign_id, week) => {
  //   // const token = localStorage.getItem('firebase_token');
  //   if (!token) throw new Error("No token found");

  //   if (!campaign_id) throw new Error('Campaign ID was not provided');
  //   if (!['current-week', 'previous-week'].includes(week)) throw new Error('Invalid week parameter');

  //   // console.log('endpoint requesting: ', `${this.baseURL}/text-campaign/touches/${week}/?campaign_id=` + campaign_id);

  //   const request = new Request(`${this.baseURL}/text-campaign/touches/${week}/?campaign_id=` + campaign_id, {
  //     method: 'GET',
  //     credentials: 'include',
  //     headers: {
  //       'Authorization': `Bearer ${token}`,
  //       'X-client_type': 'web'
  //     }
  //   });

  //   const response = await fetch(request);
  //   const data = await response.json();
  //   return data;
  // };



  getAllUserTextCampaigns = async (token, user_id, campaign_id, signal) => {
    // const token = localStorage.getItem('firebase_token');

    if (token) {
      if (user_id && campaign_id) {
        // Create request
        const request = new Request(`${this.baseURL}/text-campaign/all-user/?user_id=` + user_id + '&campaign_id=' + campaign_id, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          },
          signal: signal
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        // localStorage.setItem('text-campaigns', JSON.stringify(data));
        return data;
      }
      else {
        throw new Error('user ID or campaign ID was not provided');
      }
    } else {
      console.error("No token found");
    }
  };


  // updateTextCampaign = async (token, id, name, description, status, campaign_id, user_id) => {
  //   // const localUser = JSON.parse(localStorage.getItem('user')) ?? {};
  //   // const token = localUser['oauth_account']['access_token'];
  //   // const token = localStorage.getItem('firebase_token');

  //   const formData = {
  //     "id": id,
  //     "name": name,
  //     "description": description,
  //     "status": status,
  //     "campaign_id": campaign_id,
  //     "user_id": user_id,
  //   };

  //   if (token) {
  //     if (id) {
  //       // Create request
  //       const request = new Request(`${this.baseURL}/text-campaign/`, {
  //         method: 'PUT',
  //         headers: {
  //           'accept': 'application/json',
  //           'Content-Type': 'application/json',
  //           'Authorization': `Bearer ${token}`,
  //           'X-client_type': 'web'
  //         },
  //         body: JSON.stringify(formData),
  //         credentials: 'include',
  //       });
  //       // Fetch request
  //       const response = await fetch(request);
  //       const data = await response.json();
  //       // console.log(data);
  //       return data;

  //     } else {
  //       throw new Error('ID or Name was not provided');
  //     }
  //   } else {
  //     console.error("No token found");
  //   }
  // };


  // deleteTextCampaign = async (token, name) => {
  //   // const token = localStorage.getItem('firebase_token');

  //   if (token) {
  //     if (name) {
  //       console.log("Here 3");
  //       // Create request
  //       const request = new Request(`${this.baseURL}/text-campaign/?name=` + name, {
  //         method: 'DELETE',
  //         credentials: 'include',
  //         //headers: {'Authorization': `Bearer ${token}`}
  //       });
  //       // Fetch request
  //       const response = await fetch(request);
  //       console.log("Here 4");
  //       console.log(response.status);


  //       if (response.status === 204) {
  //         return true;
  //       } else {
  //         const data = await response.json();
  //         if (data.detail) {
  //           throw data.detail;
  //         }
  //         throw data;
  //       }
  //     } else {
  //       throw new Error('TextCampaign Name was not provided');
  //     }
  //   } else {
  //     console.error("No token found");
  //   }
  // };

}

export default new TextCampaign();