// import React, { useState, useEffect, useRef } from 'react';
// import { useHistory } from "react-router-dom";
// import { Container, Card, Button, Alert } from 'react-bootstrap';


// import authAPI from '../../apis/auth/auth';
// import LoadingSpinner from "../../components/Spinner/LoadingSpinner";
// import { useUserContext } from "../../contexts/UserContext";

// import { PRIVACY_POLICY_TEXT, DISCLAIMER_TEXT, TERMS_OF_USE_TEXT } from '../../assets/documents/LegalTexts';


// function createMarkup(text) {
//     return { __html: text };
// }



// function UserTermsPage() {
//     const { loading, campaignUser, currentUser, setUser } = useUserContext();
//     const history = useHistory();

//     const documentOrder = ['Privacy Policy', 'Disclaimer', 'Terms of Use'];
//     const [acceptedTerms, setAcceptedTerms] = useState({
//         'Privacy Policy': false,
//         'Disclaimer': false,
//         'Terms of Use': false,
//     });

//     const [currentIndex, setCurrentIndex] = useState(0);
//     const currentDocument = documentOrder[currentIndex];

//     // const [currentDocument, setCurrentDocument] = useState('Privacy Policy'); //null);
//     const [buttonDisabled, setButtonDisabled] = useState(true);
//     const [cardClasses, setCardClasses] = useState("legal-document-card-hidden");

//     useEffect(() => {
//         const timer = setTimeout(() => {
//             setCardClasses("");
//         }, 375); // Adjust the timeout to match the CSS transition

//         return () => clearTimeout(timer);
//     }, []);

//     const isMounted = useRef(false);
//     useEffect(() => {
//         isMounted.current = true;

//         if (!currentUser) {
//             history.push('/auth/login');
//         }

//         return () => {
//             isMounted.current = false;
//         };
//     }, []);









//     const containerRef = useRef(null);
//     const [isContainerReady, setIsContainerReady] = useState(false);


//     useEffect(() => {
//         // This effect checks if the ref has been attached to the DOM.
//         if (containerRef.current) {
//             setIsContainerReady(true);
//         }
//     }, [containerRef.current]);  // This does not work as expected because ref changes do not trigger effects


//     useEffect(() => {
//         if (!isContainerReady) return;

//         const container = containerRef.current;
//         const checkScroll = () => {
//             const tolerance = 5;
//             const atBottom = container.scrollHeight - container.scrollTop <= (container.clientHeight + tolerance);
//             setButtonDisabled(!atBottom);
//         };

//         // Perform an initial check in case the content is already loaded and scrollable
//         checkScroll();

//         container.addEventListener('scroll', checkScroll);
//         return () => container.removeEventListener('scroll', checkScroll);
//     }, [isContainerReady, currentDocument]);


//     // Reset scroll position and recheck scroll state when the document changes
//     useEffect(() => {
//         if (!isContainerReady) return;

//         const container = containerRef.current;
//         container.scrollTop = 0;
//         container.dispatchEvent(new Event('scroll'));  // Trigger scroll event manually to recheck scroll state
//     }, [currentDocument, isContainerReady]);


//     const handleAccept = async () => {
//         setAcceptedTerms(prev => ({ ...prev, [currentDocument]: true }));
//         if (currentIndex < documentOrder.length - 1) {
//             setCurrentIndex(currentIndex + 1);
//         } else {
//             try {
//                 const firebaseToken = await currentUser.getIdToken();
//                 const response = await authAPI.updateUserTerms(firebaseToken, true);
//                 if (response.status === "user_terms_status_update_error") {
//                     history.push('/auth/login');
//                 } else {
//                     if (response && isMounted.current) {
//                         const fetchedUserResult = await authAPI.getUser(firebaseToken);
//                         setUser(fetchedUserResult);
//                         const targetPath = (campaignUser?.title === 'Manager' || campaignUser?.title === 'Director')
//                             ? '/turrim/dashboard'
//                             : '/turrim/walkbooks';
//                         history.push(targetPath);
//                     }
//                 }
//             } catch (error) {
//                 console.error('acceptTerms error', error);
//             }
//         }
//     };



//     const getTextForCurrentDocument = () => {
//         switch (currentDocument) {
//             case 'Privacy Policy':
//                 return PRIVACY_POLICY_TEXT;
//             case 'Disclaimer':
//                 return DISCLAIMER_TEXT;
//             case 'Terms of Use':
//                 return TERMS_OF_USE_TEXT;
//             default:
//                 return 'No Document Found.';
//         }
//     };


//     return (
//         <>
//             <div className="content d-flex align-items-center p-0">
//                 <Container style={{ marginTop: '75px' }}>

//                     {((loading !== null) && (loading !== "")) ? (
//                         <LoadingSpinner />
//                     ) : (
//                         currentDocument && (
//                             <>
//                                 <div className="d-flex justify-content-center align-items-center p-0">
//                                     <Card
//                                         className={`legal-document-card ${cardClasses}`}
//                                         style={{ width: '485px' }}
//                                     >
//                                         <Card.Header as="h5" className='text-center' style={{ paddingTop: '10px', fontWeight: 'bold', fontSize: '28px' }}>{currentDocument}</Card.Header>
//                                         <hr className="horizontal-divider" style={{ marginTop: "10px", marginBottom: '0px' }} />
//                                         <Card
//                                             ref={containerRef}
//                                             id="terms-text-container"
//                                             className="terms-card-scroll-div-wrapper"
//                                             style={{ backgroundColor: 'transparent', borderRadius: '4px', margin: '0', borderWidth: '0', maxHeight: '650px', height: 'auto', width: 'calc(100% + 1px)', paddingLeft: '0px', paddingRight: '0px' }}
//                                         >
//                                             <Card.Body style={{ backgroundColor: 'white', height: 'auto' }}>
//                                                 <div style={{ paddingTop: "0px", paddingBottom: '10px' }}>
//                                                     <div dangerouslySetInnerHTML={createMarkup(getTextForCurrentDocument())} />
//                                                 </div>
//                                             </Card.Body>
//                                         </Card>
//                                         <hr className="horizontal-divider" style={{ marginTop: "0px", marginBottom: '10px' }} />


//                                         <Card.Footer className='d-flex justify-content-between align-items-center' style={{ paddingTop: "5px" }}>
//                                             <button
//                                                 className="terms-pagination-button"
//                                                 style={{ marginLeft: '15px' }}
//                                                 onClick={() => setCurrentIndex(currentIndex - 1)}
//                                                 disabled={currentIndex === 0}
//                                             >
//                                                 <span className="material-symbols-rounded">
//                                                     navigate_before
//                                                 </span>
//                                             </button>
//                                             <button
//                                                 onClick={handleAccept}
//                                                 disabled={buttonDisabled || acceptedTerms[currentDocument]}
//                                                 className={`${acceptedTerms[currentDocument] ? "terms-button-accepted" : (buttonDisabled ? "terms-button-disabled" : "terms-button-enabled")}`}
//                                             >
//                                                 {acceptedTerms[currentDocument] ? "Accepted" : "Accept"}
//                                             </button>
//                                             <button
//                                                 className="terms-pagination-button"
//                                                 style={{ marginRight: '15px' }}
//                                                 onClick={() => setCurrentIndex(currentIndex + 1)}
//                                                 disabled={currentIndex >= documentOrder.length - 1 || !acceptedTerms[currentDocument]}
//                                             >
//                                                 <span className="material-symbols-rounded">
//                                                     navigate_next
//                                                 </span>
//                                             </button>
//                                         </Card.Footer>

//                                     </Card>
//                                 </div>
//                             </>

//                         )
//                     )}
//                 </Container>
//             </div>
//         </>
//     );
// };

// export default UserTermsPage;


// import React, { useState, useEffect, useRef } from 'react';
// import { useHistory } from "react-router-dom";
// import { Container, Card } from 'react-bootstrap';

// import authAPI from '../../apis/auth/auth';
// import LoadingSpinner from "../../components/Spinner/LoadingSpinner";
// import LocalLoadingSpinner from '../../components/Spinner/LocalLoadingSpinner';
// import { useUserContext } from "../../contexts/UserContext";

// import PrivacyPolicy from '../../components/LegalDocuments/PrivacyPolicy';
// import Disclaimer from '../../components/LegalDocuments/Disclaimer';
// import TermsAndConditions from '../../components/LegalDocuments/TermsAndConditions';



// // function createMarkup(text) {
// //     return { __html: text };
// // }



// function UserTermsPage() {
//     const { loading, campaignUser, currentUser, setUser } = useUserContext();
//     const history = useHistory();

//     const documentOrder = ['Privacy Policy', 'Disclaimer', 'Terms and Conditions'];
//     const [acceptedTerms, setAcceptedTerms] = useState({
//         'Privacy Policy': false,
//         'Disclaimer': false,
//         'Terms and Conditions': false,
//     });

//     const [currentIndex, setCurrentIndex] = useState(0);
//     const currentDocument = documentOrder[currentIndex];

//     const [buttonDisabled, setButtonDisabled] = useState(true);
//     const [cardClasses, setCardClasses] = useState("legal-document-card-hidden");

//     useEffect(() => {
//         const timer = setTimeout(() => {
//             setCardClasses("");
//         }, 375);

//         return () => clearTimeout(timer);
//     }, []);

//     const isMounted = useRef(false);
//     useEffect(() => {
//         isMounted.current = true;

//         if (!currentUser) {
//             history.push('/auth/login');
//         }

//         return () => {
//             isMounted.current = false;
//         };
//     }, []);


//     // const contentRef = useRef(null);
//     const containerRef = useRef(null);
//     const [isContainerReady, setIsContainerReady] = useState(false);


//     useEffect(() => {
//         // This effect checks if the ref has been attached to the DOM.
//         if (containerRef.current) {
//             setIsContainerReady(true);
//         }
//     }, [containerRef.current]);  // This does not work as expected because ref changes do not trigger effects


//     useEffect(() => {
//         if (!isContainerReady) return;

//         const container = containerRef.current;
//         const checkScroll = () => {
//             const tolerance = 5;
//             const atBottom = container.scrollHeight - container.scrollTop <= (container.clientHeight + tolerance);
//             setButtonDisabled(!atBottom);
//         };

//         // Perform an initial check in case the content is already loaded and scrollable
//         checkScroll();

//         container.addEventListener('scroll', checkScroll);
//         return () => container.removeEventListener('scroll', checkScroll);
//     }, [isContainerReady, currentDocument]);


//     // Reset scroll position and recheck scroll state when the document changes
//     useEffect(() => {
//         if (!isContainerReady) return;

//         const container = containerRef.current;
//         container.scrollTop = 0;
//         container.dispatchEvent(new Event('scroll'));  // Trigger scroll event manually to recheck scroll state

//     }, [currentDocument, isContainerReady]);


//     const handleAccept = async () => {
//         setAcceptedTerms(prev => ({ ...prev, [currentDocument]: true }));
//         if (currentIndex < documentOrder.length - 1) {
//             setCurrentIndex(currentIndex + 1);
//         } else {
//             try {
//                 const firebaseToken = await currentUser.getIdToken();
//                 const response = await authAPI.updateUserTerms(firebaseToken, true);
//                 if (response.status === "user_terms_status_update_error") {
//                     history.push('/auth/login');
//                 } else {
//                     if (response && isMounted.current) {
//                         const fetchedUserResult = await authAPI.getUser(firebaseToken);
//                         setUser(fetchedUserResult);
//                         const targetPath = (campaignUser?.title === 'Manager' || campaignUser?.title === 'Director')
//                             ? '/turrim/dashboard'
//                             : '/turrim/walkbooks';
//                         history.push(targetPath);
//                     }
//                 }
//             } catch (error) {
//                 console.error('acceptTerms error', error);
//             }
//         }
//     };


//     const getDocumentComponent = () => {
//         switch (currentDocument) {
//             case 'Privacy Policy':
//                 return (
//                     <PrivacyPolicy
//                         // ref={containerRef}
//                         // id="terms-text-container"
//                         // className="terms-card-scroll-div-wrapper"
//                         // style={{ backgroundColor: 'transparent', borderRadius: '4px', margin: '0', borderWidth: '0', height: '650px', width: 'calc(100% + 1px)', padding: '0 0 0px 0' }}
//                     />
//                 );
//             case 'Disclaimer':
//                 return <Disclaimer />;
//             case 'Terms and Conditions':
//                 return <TermsAndConditions />;
//             default:
//                 return 'No Document Found.';
//         }
//     };


//     // return (
//     //     <>
//     //         <div className="content d-flex align-items-center p-0">
//     //             <Container style={{ marginTop: '75px' }}>

//     //                 {((loading !== null) && (loading !== "")) ? (
//     //                     <LoadingSpinner />
//     //                 ) : (
//     //                     currentDocument && (
//     //                         <>
//     //                             <div className="d-flex justify-content-center align-items-center p-0">
//     //                                 <Card
//     //                                     className={`legal-document-card ${cardClasses}`}
//     //                                     style={{ width: '485px' }}
//     //                                 >
//     //                                     <Card.Header as="h5" className='text-center' style={{ paddingTop: '10px', fontWeight: 'bold', fontSize: '28px' }}>{currentDocument}</Card.Header>
//     //                                     <hr className="horizontal-divider" style={{ marginTop: "10px", marginBottom: '0px' }} />
//     //                                     {/* <Card
//     //                                         ref={containerRef}
//     //                                         id="terms-text-container"
//     //                                         // className="terms-card-scroll-div-wrapper"
//     //                                         style={{ backgroundColor: 'transparent', borderRadius: '4px', margin: '0', borderWidth: '0', maxHeight: '650px', height: 'auto', width: 'calc(100% + 1px)', paddingLeft: '0px', paddingRight: '0px' }}
//     //                                     >
//     //                                         <Card.Body className="terms-card-scroll-div-wrapper" style={{ backgroundColor: 'white', height: 'auto', padding: '0' }}>
//     //                                             <div style={{ paddingTop: "0px", paddingBottom: '0px' }}>
//     //                                                 {getDocumentComponent()}
//     //                                             </div>
//     //                                         </Card.Body>
//     //                                     </Card> */}
//     //                                     <Card.Body
//     //                                         ref={containerRef}
//     //                                         id="terms-text-container"
//     //                                         className="terms-card-scroll-div-wrapper"
//     //                                         style={{ backgroundColor: 'transparent', borderRadius: '4px', margin: '0', borderWidth: '0', height: '650px', width: 'calc(100% + 1px)', padding: '0 0 0px 0', overflowY: 'hidden'}}
//     //                                     >
//     //                                         <div style={{ paddingTop: "0px", paddingBottom: '0px', height: '100%' }}>
//     //                                             {getDocumentComponent()}
//     //                                         </div>
//     //                                     </Card.Body>
//     //                                     <hr className="horizontal-divider" style={{ marginTop: "0px", marginBottom: '10px' }} />


//     //                                     <Card.Footer className='d-flex justify-content-between align-items-center' style={{ paddingTop: "5px" }}>
//     //                                         <button
//     //                                             className="terms-pagination-button"
//     //                                             style={{ marginLeft: '15px' }}
//     //                                             onClick={() => setCurrentIndex(currentIndex - 1)}
//     //                                             disabled={currentIndex === 0}
//     //                                         >
//     //                                             <span className="material-symbols-rounded">
//     //                                                 navigate_before
//     //                                             </span>
//     //                                         </button>
//     //                                         <button
//     //                                             onClick={handleAccept}
//     //                                             disabled={buttonDisabled || acceptedTerms[currentDocument]}
//     //                                             className={`${acceptedTerms[currentDocument] ? "terms-button-accepted" : (buttonDisabled ? "terms-button-disabled" : "terms-button-enabled")}`}
//     //                                         >
//     //                                             {acceptedTerms[currentDocument] ? "Accepted" : "Accept"}
//     //                                         </button>
//     //                                         <button
//     //                                             className="terms-pagination-button"
//     //                                             style={{ marginRight: '15px' }}
//     //                                             onClick={() => setCurrentIndex(currentIndex + 1)}
//     //                                             disabled={currentIndex >= documentOrder.length - 1 || !acceptedTerms[currentDocument]}
//     //                                         >
//     //                                             <span className="material-symbols-rounded">
//     //                                                 navigate_next
//     //                                             </span>
//     //                                         </button>
//     //                                     </Card.Footer>

//     //                                 </Card>
//     //                             </div>
//     //                         </>

//     //                     )
//     //                 )}
//     //             </Container>
//     //         </div>
//     //     </>
//     // );

//     return (
//         <>
//             <div className="content d-flex align-items-center p-0">
//                 <Container style={{ marginTop: '75px' }}>
//                     {loading ? (
//                         <LoadingSpinner />
//                     ) : (
//                         currentDocument && (
//                             <>
//                                 <div className="d-flex justify-content-center align-items-center p-0">
//                                     <Card
//                                         className={`legal-document-card ${cardClasses}`}
//                                         style={{ width: '485px' }}
//                                     >
//                                         <Card.Header as="h5" className='text-center' style={{ paddingTop: '10px', fontWeight: 'bold', fontSize: '28px' }}>{currentDocument}</Card.Header>
//                                         <hr className="horizontal-divider" style={{ marginTop: "10px", marginBottom: '0px' }} />
//                                         <Card.Body
//                                             ref={containerRef}
//                                             id="terms-text-container"
//                                             // className="terms-card-scroll-div-wrapper"
//                                             style={{ backgroundColor: 'transparent', borderRadius: '4px', margin: '0', borderWidth: '0', height: '650px', width: 'calc(100% + 1px)', padding: '0 0 0px 0' }}
//                                         >
//                                             <div style={{ paddingTop: "0px", paddingBottom: '0px', height: '100%' }}>
//                                                 {getDocumentComponent()}
//                                             </div>
//                                         </Card.Body>
//                                         {getDocumentComponent()}
//                                         <hr className="horizontal-divider" style={{ marginTop: "0px", marginBottom: '10px' }} />
//                                         <Card.Footer className='d-flex justify-content-between align-items-center' style={{ paddingTop: "5px" }}>
//                                             <button
//                                                 className="terms-pagination-button"
//                                                 style={{ marginLeft: '15px' }}
//                                                 onClick={() => setCurrentIndex(currentIndex - 1)}
//                                                 disabled={currentIndex === 0}
//                                             >
//                                                 <span className="material-symbols-rounded">
//                                                     navigate_before
//                                                 </span>
//                                             </button>
//                                             <button
//                                                 onClick={handleAccept}
//                                                 disabled={buttonDisabled || acceptedTerms[currentDocument]}
//                                                 className={`${acceptedTerms[currentDocument] ? "terms-button-accepted" : (buttonDisabled ? "terms-button-disabled" : "terms-button-enabled")}`}
//                                             >
//                                                 {acceptedTerms[currentDocument] ? "Accepted" : "Accept"}
//                                             </button>
//                                             <button
//                                                 className="terms-pagination-button"
//                                                 style={{ marginRight: '15px' }}
//                                                 onClick={() => setCurrentIndex(currentIndex + 1)}
//                                                 disabled={currentIndex >= documentOrder.length - 1 || !acceptedTerms[currentDocument]}
//                                             >
//                                                 <span className="material-symbols-rounded">
//                                                     navigate_next
//                                                 </span>
//                                             </button>
//                                         </Card.Footer>
//                                     </Card>
//                                 </div>
//                             </>
//                         )
//                     )}
//                 </Container>
//             </div>
//         </>
//     );

// };

// export default UserTermsPage;


// import React, { useState, useEffect, useRef } from 'react';
// import { useHistory } from "react-router-dom";
// import { Container, Card } from 'react-bootstrap';

// import authAPI from '../../apis/auth/auth';
// import LoadingSpinner from "../../components/Spinner/LoadingSpinner";
// import { useUserContext } from '../../contexts/UserContext';

// import PrivacyPolicy from '../../components/LegalDocuments/PrivacyPolicy';
// import Disclaimer from '../../components/LegalDocuments/Disclaimer';
// import TermsAndConditions from '../../components/LegalDocuments/TermsAndConditions';

// function UserTermsPage() {
//     const { loading, campaignUser, currentUser, setUser } = useUserContext();
//     const history = useHistory();

//     const documentOrder = ['Privacy Policy', 'Disclaimer', 'Terms and Conditions'];
//     const [acceptedTerms, setAcceptedTerms] = useState({
//         'Privacy Policy': false,
//         'Disclaimer': false,
//         'Terms and Conditions': false,
//     });

//     const [currentIndex, setCurrentIndex] = useState(0);
//     const currentDocument = documentOrder[currentIndex];

//     const [buttonDisabled, setButtonDisabled] = useState(true);
//     const [cardClasses, setCardClasses] = useState("legal-document-card-hidden");

//     useEffect(() => {
//         const timer = setTimeout(() => {
//             setCardClasses("");
//         }, 375);

//         return () => clearTimeout(timer);
//     }, []);

//     const isMounted = useRef(false);
//     useEffect(() => {
//         isMounted.current = true;

//         if (!currentUser) {
//             history.push('/auth/login');
//         }

//         return () => {
//             isMounted.current = false;
//         };
//     }, []);

//     const handleScrollToBottom = () => {
//         setButtonDisabled(false);
//     };

//     const containerRef = useRef(null);
//     const [isContainerReady, setIsContainerReady] = useState(false);

//     useEffect(() => {
//         if (containerRef.current) {
//             setIsContainerReady(true);
//         }
//     }, [containerRef.current]);

//     useEffect(() => {
//         if (!isContainerReady) return;

//         const container = containerRef.current;
//         const checkScroll = () => {
//             const tolerance = 5;
//             const atBottom = container.scrollHeight - container.scrollTop <= (container.clientHeight + tolerance);
//             setButtonDisabled(!atBottom);
//         };

//         checkScroll();
//         container.addEventListener('scroll', checkScroll);
//         return () => container.removeEventListener('scroll', checkScroll);
//     }, [isContainerReady, currentDocument]);

//     useEffect(() => {
//         if (!isContainerReady) return;

//         const container = containerRef.current;
//         container.scrollTop = 0;
//         container.dispatchEvent(new Event('scroll'));
//     }, [currentDocument, isContainerReady]);

//     const handleAccept = async () => {
//         setAcceptedTerms(prev => ({ ...prev, [currentDocument]: true }));
//         if (currentIndex < documentOrder.length - 1) {
//             setCurrentIndex(currentIndex + 1);
//         } else {
//             try {
//                 const firebaseToken = await currentUser.getIdToken();
//                 const response = await authAPI.updateUserTerms(firebaseToken, true);
//                 if (response.status === "user_terms_status_update_error") {
//                     history.push('/auth/login');
//                 } else {
//                     if (response && isMounted.current) {
//                         const fetchedUserResult = await authAPI.getUser(firebaseToken);
//                         setUser(fetchedUserResult);
//                         const targetPath = (campaignUser?.title === 'Manager' || campaignUser?.title === 'Director')
//                             ? '/turrim/dashboard'
//                             : '/turrim/walkbooks';
//                         history.push(targetPath);
//                     }
//                 }
//             } catch (error) {
//                 console.error('acceptTerms error', error);
//             }
//         }
//     };

//     const getDocumentComponent = () => {
//         switch (currentDocument) {
//             case 'Privacy Policy':
//                 return <PrivacyPolicy onScrollToBottom={handleScrollToBottom} />;
//             case 'Disclaimer':
//                 return <Disclaimer onScrollToBottom={handleScrollToBottom} />;
//             case 'Terms and Conditions':
//                 return <TermsAndConditions onScrollToBottom={handleScrollToBottom} />;
//             default:
//                 return 'No Document Found.';
//         }
//     };

//     return (
//         <>
//             <div className="content d-flex align-items-center p-0">
//                 <Container style={{ marginTop: '75px' }}>
//                     {loading ? (
//                         <LoadingSpinner />
//                     ) : (
//                         currentDocument && (
//                             <>
//                                 <div className="d-flex justify-content-center align-items-center p-0">
//                                     <Card
//                                         className={`legal-document-card ${cardClasses}`}
//                                         style={{ width: '485px' }}
//                                     >
//                                         <Card.Header as="h5" className='text-center' style={{ paddingTop: '10px', fontWeight: 'bold', fontSize: '28px' }}>{currentDocument}</Card.Header>
//                                         <hr className="horizontal-divider" style={{ marginTop: "10px", marginBottom: '0px' }} />
//                                         <Card.Body
//                                             ref={containerRef}
//                                             id="terms-text-container"
//                                             // className="terms-card-scroll-div-wrapper"
//                                             style={{ backgroundColor: 'transparent', borderRadius: '4px', margin: '0', borderWidth: '0', height: '650px', width: 'calc(100% + 1px)', padding: '0 0 0px 0' }}
//                                         >
//                                             <div style={{ paddingTop: "0px", paddingBottom: '0px', height: '100%' }}>
//                                                 {getDocumentComponent()}
//                                             </div>
//                                         </Card.Body>
//                                         <hr className="horizontal-divider" style={{ marginTop: "0px", marginBottom: '10px' }} />
//                                         <Card.Footer className='d-flex justify-content-between align-items-center' style={{ paddingTop: "5px" }}>
//                                             <button
//                                                 className="terms-pagination-button"
//                                                 style={{ marginLeft: '15px' }}
//                                                 onClick={() => setCurrentIndex(currentIndex - 1)}
//                                                 disabled={currentIndex === 0}
//                                             >
//                                                 <span className="material-symbols-rounded">
//                                                     navigate_before
//                                                 </span>
//                                             </button>
//                                             <button
//                                                 onClick={handleAccept}
//                                                 disabled={buttonDisabled || acceptedTerms[currentDocument]}
//                                                 className={`${acceptedTerms[currentDocument] ? "terms-button-accepted" : (buttonDisabled ? "terms-button-disabled" : "terms-button-enabled")}`}
//                                             >
//                                                 {acceptedTerms[currentDocument] ? "Accepted" : "Accept"}
//                                             </button>
//                                             <button
//                                                 className="terms-pagination-button"
//                                                 style={{ marginRight: '15px' }}
//                                                 onClick={() => setCurrentIndex(currentIndex + 1)}
//                                                 disabled={currentIndex >= documentOrder.length - 1 || !acceptedTerms[currentDocument]}
//                                             >
//                                                 <span className="material-symbols-rounded">
//                                                     navigate_next
//                                                 </span>
//                                             </button>
//                                         </Card.Footer>
//                                     </Card>
//                                 </div>
//                             </>
//                         )
//                     )}
//                 </Container>
//             </div>
//         </>
//     );
// }

// export default UserTermsPage;



import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { Container, Card } from 'react-bootstrap';

import authAPI from '../../apis/auth/auth';
import LoadingSpinner from "../../components/Spinner/LoadingSpinner";
import { useUserContext } from '../../contexts/UserContext';

import PrivacyPolicy from '../../components/LegalDocuments/PrivacyPolicy';
import Disclaimer from '../../components/LegalDocuments/Disclaimer';
import TermsAndConditions from '../../components/LegalDocuments/TermsAndConditions';

function UserTermsPage() {
    const { loading, campaignUser, currentUser, setUser } = useUserContext();
    const history = useHistory();

    const documentOrder = ['Privacy Policy', 'Disclaimer', 'Terms and Conditions'];
    const [acceptedTerms, setAcceptedTerms] = useState({
        'Privacy Policy': false,
        'Disclaimer': false,
        'Terms and Conditions': false,
    });

    const [currentIndex, setCurrentIndex] = useState(0);
    const currentDocument = documentOrder[currentIndex];

    const [buttonDisabled, setButtonDisabled] = useState(false);  // Allow button by default
    const [cardClasses, setCardClasses] = useState("legal-document-card-hidden");

    useEffect(() => {
        const timer = setTimeout(() => {
            setCardClasses("");
        }, 375);

        return () => clearTimeout(timer);
    }, []);

    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;

        if (!currentUser) {
            history.push('/auth/login');
        }

        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleAccept = async () => {
        setAcceptedTerms(prev => ({ ...prev, [currentDocument]: true }));
        if (currentIndex < documentOrder.length - 1) {
            setCurrentIndex(currentIndex + 1);
        } else {
            try {
                const firebaseToken = await currentUser.getIdToken();
                const response = await authAPI.updateUserTerms(firebaseToken, true);
                if (response.status === "user_terms_status_update_error") {
                    history.push('/auth/login');
                } else {
                    if (response && isMounted.current) {
                        const fetchedUserResult = await authAPI.getUser(firebaseToken);
                        setUser(fetchedUserResult);
                        const targetPath = (campaignUser?.title === 'Manager' || campaignUser?.title === 'Director')
                            ? '/turrim/dashboard'
                            : '/turrim/walkbooks';
                        history.push(targetPath);
                    }
                }
            } catch (error) {
                console.error('acceptTerms error', error);
            }
        }
    };

    const getDocumentComponent = () => {
        switch (currentDocument) {
            case 'Privacy Policy':
                return <PrivacyPolicy />;
            case 'Disclaimer':
                return <Disclaimer />;
            case 'Terms and Conditions':
                return <TermsAndConditions />;
            default:
                return 'No Document Found.';
        }
    };

    return (
        <>
            <div className="content d-flex align-items-center p-0">
                <Container style={{ marginTop: '75px' }}>
                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        currentDocument && (
                            <>
                                <div className="d-flex justify-content-center align-items-center p-0">
                                    <Card
                                        className={`legal-document-card ${cardClasses}`}
                                        style={{ width: '485px' }}
                                    >
                                        <Card.Header as="h5" className='text-center' style={{ paddingTop: '10px', fontWeight: 'bold', fontSize: '28px' }}>{currentDocument}</Card.Header>
                                        <hr className="horizontal-divider" style={{ marginTop: "10px", marginBottom: '0px' }} />
                                        <Card.Body
                                            id="terms-text-container"
                                            // className="terms-card-scroll-div-wrapper"
                                            style={{ backgroundColor: 'transparent', borderRadius: '4px', margin: '0', borderWidth: '0', height: '650px', width: '100%', padding: '0 0 0px 0' }}
                                        >
                                            <div style={{ paddingTop: "0px", paddingBottom: '0px', height: '100%' }}>
                                                {getDocumentComponent()}
                                            </div>
                                        </Card.Body>
                                        <hr className="horizontal-divider" style={{ marginTop: "0px", marginBottom: '10px' }} />
                                        <Card.Footer className='d-flex justify-content-between align-items-center' style={{ paddingTop: "5px" }}>
                                            <button
                                                className="terms-pagination-button"
                                                style={{ marginLeft: '15px' }}
                                                onClick={() => setCurrentIndex(currentIndex - 1)}
                                                disabled={currentIndex === 0}
                                            >
                                                <span className="material-symbols-rounded material-symbols-rounded-filled">
                                                    navigate_before
                                                </span>
                                            </button>
                                            <button
                                                onClick={handleAccept}
                                                className={`${acceptedTerms[currentDocument] ? "terms-button-accepted" : "terms-button-enabled"}`}
                                            >
                                                {acceptedTerms[currentDocument] ? "Accepted" : "Accept"}
                                            </button>
                                            <button
                                                className="terms-pagination-button"
                                                style={{ marginRight: '15px' }}
                                                onClick={() => setCurrentIndex(currentIndex + 1)}
                                                disabled={currentIndex >= documentOrder.length - 1 || !acceptedTerms[currentDocument]}
                                            >
                                                <span className="material-symbols-rounded material-symbols-rounded-filled">
                                                    navigate_next
                                                </span>
                                            </button>
                                        </Card.Footer>
                                    </Card>
                                </div>
                            </>
                        )
                    )}
                </Container>
            </div>
        </>
    );
}

export default UserTermsPage;
