import { useRef, useCallback } from 'react';

export const useMapContextManager = () => {
  const contextCount = useRef(0);
  const maxContexts = 8; // Adjust based on your application's needs and performance

  const createContext = useCallback(() => {
    if (contextCount.current >= maxContexts) {
    //   console.warn('Max WebGL contexts reached. Consider destroying unused contexts.');
    }
    contextCount.current++;
    // console.log('WebGL context created. Total contexts:', contextCount.current);
  }, []);

  const destroyContext = useCallback(() => {
    if (contextCount.current > 0) {
      contextCount.current--;
    //   console.log('WebGL context destroyed. Total contexts:', contextCount.current);
    }
  }, []);

  return { createContext, destroyContext };
};



// import { useRef, useCallback, useEffect } from 'react';

// export const useMapContextManager = () => {
//   const contextCount = useRef(0);
//   const maxContexts = 8;
//   const contexts = useRef(new Set());

//   const createContext = useCallback(() => {
//     if (contextCount.current >= maxContexts) {
//       console.warn('Max WebGL contexts reached. Consider destroying unused contexts.');
//     }
//     const context = { id: Date.now(), cleanup: null };
//     contexts.current.add(context);
//     contextCount.current++;
//     console.log(`WebGL context created. Total contexts: ${contextCount.current}`);
//     return context.id;
//   }, []);

//   const registerCleanup = useCallback((contextId, cleanupFunction) => {
//     const context = Array.from(contexts.current).find(c => c.id === contextId);
//     if (context) {
//       context.cleanup = cleanupFunction;
//     }
//   }, []);

//   const destroyContext = useCallback((contextId) => {
//     const context = Array.from(contexts.current).find(c => c.id === contextId);
//     if (context) {
//       if (typeof context.cleanup === 'function') {
//         context.cleanup();
//       }
//       contexts.current.delete(context);
//       contextCount.current--;
//       console.log(`WebGL context destroyed. Total contexts: ${contextCount.current}`);
//     }
//   }, []);

//   useEffect(() => {
//     return () => {
//       contexts.current.forEach(context => {
//         if (typeof context.cleanup === 'function') {
//           context.cleanup();
//         }
//       });
//       contexts.current.clear();
//       contextCount.current = 0;
//       console.log('All WebGL contexts cleaned up');
//     };
//   }, []);

//   return { createContext, registerCleanup, destroyContext };
// };


let globalContextCount = 0;

export const trackWebGLContext = () => {
  const oldGetContext = HTMLCanvasElement.prototype.getContext;
  HTMLCanvasElement.prototype.getContext = function() {
    const context = oldGetContext.apply(this, arguments);
    if (arguments[0] === 'webgl' || arguments[0] === 'webgl2') {
      globalContextCount++;
      // console.log(`WebGL context created. Global count: ${globalContextCount}`);
      // console.trace('WebGL context creation stack trace:');
    }
    return context;
  };
};

export const logWebGLContexts = () => {
  // console.log(`Current global WebGL context count: ${globalContextCount}`);
};