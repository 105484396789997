class VoterGroup {

  constructor() {
    this.baseURL = process.env.REACT_APP_API_BASE_URL;
  }


  createVoterGroup = async (token, name, type, creationMethod, filterParams, campaign_id, user_id) => {

    // Create data JSON
    const formData = {
      "name": name,
      "type": type, // "Static" or "Dynamic
      "creation_method": creationMethod,
      "status": "Pending",
      // "geo_data": geoData[0],
      "filter_params": filterParams,
      "campaign_id": campaign_id,
      "user_id": user_id,
    };

    // console.log(formData);

    if (token) {
      const request = new Request(`${this.baseURL}/voter-group/`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        body: JSON.stringify(formData),
        credentials: 'include',
      });

      // Fetch request
      const response = await fetch(request);
      // 500 error handling
      if (response.status === 500) {
        throw new Error('Internal server error');
      }
      // 400 error handling
      const data = await response.json();
      if (response.status >= 400 && response.status < 500) {
        if (data.detail) {
          throw data.detail;
        }
        throw data;
      }
      return data;
    } else {
      console.error("No token found");
    }
  };



  getBreakdownData = async (token, campaignId, filterParams) => {
    if (token) {
      const formData = {
        campaign_id: campaignId,
        ...filterParams
      };

      const request = new Request(`${this.baseURL}/voter-group/voter-breakdown/`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        body: JSON.stringify(formData),
        credentials: 'include',
      });

      try {
        const response = await fetch(request);
        
        if (response.status === 500) {
          throw new Error('Internal server error');
        }

        const data = await response.json();

        if (response.status >= 400 && response.status < 500) {
          if (data.detail) {
            throw data.detail;
          }
          throw data;
        }

        return data;
      } catch (error) {
        console.error("Error fetching breakdown data:", error);
        throw error;
      }
    } else {
      console.error("No token found");
      throw new Error("No token found");
    }
  };



  getVoterCount = async (token, campaignId, filters) => {
    if (token) {
      const request = new Request(`${this.baseURL}/voter-group/count/?campaign_id=${campaignId}`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        body: JSON.stringify(filters),
        credentials: 'include',
      });

      const response = await fetch(request);
      if (!response.ok) {
        throw new Error('Failed to fetch voter count');
      }
      const data = await response.json();
      return data.count;
    } else {
      console.error("No token found");
    }
  };



  uploadVoterFile = async (token, name, campaignId, userId, csvContent) => {
    if (!token) {
      console.error("No token found");
      return;
    }

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const file = new File([blob], 'voter_file.csv', { type: 'text/csv' });

    const formData = new FormData();
    formData.append('file', file);

    const queryParams = new URLSearchParams({
      name: name,
      campaign_id: campaignId,
      user_id: userId
    }).toString();


    const request = new Request(`${this.baseURL}/voter-group/upload/?${queryParams}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-client_type': 'web'
      },
      body: formData,
      credentials: 'include',
    });


    const response = await fetch(request);
    if (!response.ok) {
      throw new Error('Failed to upload voter file');
    }
    const data = await response.json();
    return data;
  };



  getUploadStatus = async (token, taskId) => {
    if (!token) {
      console.error("No token found");
      return;
    }

    const request = new Request(`${this.baseURL}/voter-group/upload-status/${taskId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-client_type': 'web'
      },
      credentials: 'include',
    });

    const response = await fetch(request);
    if (!response.ok) {
      throw new Error('Failed to get upload status');
    }
    const data = await response.json();
    return data;
  };



  mergeVoterFileData = async (token, campaignId, voterIds) => {
    if (token) {
      const request = new Request(`${this.baseURL}/voter-group/merge/`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        body: JSON.stringify({ campaign_id: campaignId, voter_ids: voterIds }),
        credentials: 'include',
      });

      const response = await fetch(request);
      if (!response.ok) {
        throw new Error('Failed to merge voter data');
      }
      const data = await response.json();
      return data;
    } else {
      console.error("No token found");
    }
  };



  getVoterGroup = async (token, id) => {
    // const token = localStorage.getItem('firebase_token');

    if (token) {
      if (id) {

        // Create request
        const request = new Request(`${this.baseURL}/voter-group/?group_id=` + id, {
          method: 'GET',
          credentials: 'include',
          headers: { 'Authorization': `Bearer ${token}` }
        });

        // Fetch request
        const response = await fetch(request);

        const data = await response.json();
        // console.log(data);
        return data;
        
      }
      else {
        throw new Error('ID was not provided');
      }
    } else {
      console.error("No token found");
    }
  };


  // getVoterGroup = async (token, id) => {
  //   if (!token) {
  //     console.error("No token found");
  //     return;
  //   }
    
  //   if (!id) {
  //     throw new Error('ID was not provided');
  //   }
  
  //   // Create request
  //   const request = new Request(`${this.baseURL}/voter-group/?group_id=${id}`, {
  //     method: 'GET',
  //     credentials: 'include',
  //     headers: { 'Authorization': `Bearer ${token}` }
  //   });
  
  //   try {
  //     const response = await fetch(request);
      
  //     // If response is not ok (includes 404), throw an error
  //     if (!response.ok) {
  //       // You can customize this error object as needed
  //       throw new Error(`Voter group not found (${response.status})`);
  //     }
  
  //     const data = await response.json();
  //     return data;
  //   } catch (error) {
  //     // Handle the error here instead of letting it propagate to the console
  //     // You can choose to log it differently or not at all
  //     if (error.message.includes('not found')) {
  //       // Silently handle 404s or log them differently
  //       return null; // or return some other value to indicate "not found"
  //     }
      
  //     // Re-throw other types of errors
  //     throw error;
  //   }
  // };



  getVoterGroupVoters = async (token, id, skip, limit) => {
    // console.log("In api call");
    // console.log("id: ", id);
    // console.log("skip: ", skip);
    // console.log("limit: ", limit);
    // const token = localStorage.getItem('firebase_token');

    if (token) {
      // if (id && skip && limit) {
      if (id !== undefined && id !== null && skip !== undefined && skip !== null && limit !== undefined && limit !== null) {
        // Create request
        const request = new Request(`${this.baseURL}/voter-group/voters/?group_id=` + id + '&skip=' + skip + '&limit=' + limit, {
          method: 'GET',
          credentials: 'include',
          headers: { 'Authorization': `Bearer ${token}` }
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        //console.log(data);
        return data;
      }
      else {
        throw new Error('One or more parameters were not provided');
        // throw new Error('ID was not provided');
      }
    } else {
      console.error("No token found");
    }
  };



  getAllVoterGroups = async (token, campaign_id, signal) => {
    // const token = localStorage.getItem('firebase_token');
    if (token) {
      if (campaign_id) {
        // Create request
        const request = new Request(`${this.baseURL}/voter-group/all/?campaign_id=` + campaign_id, {
          method: 'GET',
          credentials: 'include',
          headers: { 'Authorization': `Bearer ${token}` },
          signal: signal
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        //console.log(data);
        return data;
      }
      else {
        throw new Error('ID or Name was not provided');
      }
    } else {
      console.error("No token found");
    }
  };



  updateVoterGroup = async (token, id, name, description, status, campaign_id, user_id) => {
    // const localUser = JSON.parse(localStorage.getItem('user')) ?? {};
    // const token = localUser['oauth_account']['access_token'];
    // const token = localStorage.getItem('firebase_token');

    const formData = {
      "id": id,
      "name": name,
      "description": description,
      "status": status,
      "campaign_id": campaign_id,
      "user_id": user_id,
    };

    if (token) {
      if (id) {
        // Create request
        const request = new Request(`${this.baseURL}/voter-group/`, {
          method: 'PUT',
          headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          },
          body: JSON.stringify(formData),
          credentials: 'include',
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        // console.log(data);
        return data;

      } else {
        throw new Error('ID or Name was not provided');
      }
    } else {
      console.error("No token found");
    }
  };



  deleteVoterGroup = async (token, voterGroupId, campaignId) => {
    if (!token) {
      throw new Error('No token found');
    }
    if (!voterGroupId || !campaignId) {
      throw new Error('Voter Group ID or Campaign ID was not provided');
    }
  
    const request = new Request(
      `${this.baseURL}/voter-group/delete/?voter_group_id=${voterGroupId}&campaign_id=${campaignId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'X-client_type': 'web',
        },
      }
    );
  
    const response = await fetch(request);
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.detail || 'Failed to delete voter group');
    }
    return { status: 'success', message: 'Voter group deleted successfully' };
  };



}

export default new VoterGroup();