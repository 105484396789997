class Survey {

    constructor() {
        this.baseURL = process.env.REACT_APP_API_BASE_URL;
    }


    createSurvey = async (token, name, description, questions, campaign_id, user_id) => {
        const formattedQuestions = questions.map(question => ({
            text: question.text,
            answers: question.answers.map(answer => ({
                text: answer.text.text // Use the 'text' property of the 'text' object
            }))
        }));


        const formData = {
            name: name,
            questions: formattedQuestions,
            campaign_id: campaign_id,
            user_id: user_id,
            description: description, // Add this if you want to include a description
            record_status: "is_active" // This is the default, but you can include it explicitly
        };

        console.log(formData);

        // console.log(JSON.stringify(formData));

        if (token) {
            const request = new Request(`${this.baseURL}/survey/`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-client_type': 'web'
                },
                body: JSON.stringify(formData),
                credentials: 'include',
            });

            try {
                const response = await fetch(request);
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.detail || 'Failed to create survey');
                }
                const data = await response.json();
                return data;
            } catch (error) {
                console.error('Error creating survey:', error);
                throw error;
            }
        } else {
            throw new Error("No token found");
        }
    };



    checkUniqueSurveyName = async (token, campaignId, name) => {
        if (token) {
            const request = new Request(`${this.baseURL}/survey/check-name/${campaignId}/?name=${encodeURIComponent(name)}`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-client_type': 'web'
                },
                credentials: 'include',
            });

            const response = await fetch(request);
            if (!response.ok) {
                throw new Error('Failed to check survey name uniqueness');
            }
            const data = await response.json();
            return data.is_unique;
        } else {
            throw new Error("No token found");
        }
    };



    getSurvey = async (token, id, campaign_id) => {
        if (token) {
            if (id) {
                const request = new Request(`${this.baseURL}/survey/?id=${id}&campaign_id=${campaign_id}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                const response = await fetch(request);

                if (response.status === 404) {
                    throw new Error('Survey not found');
                }

                const data = await response.json();
                return data;
            } else {
                throw new Error('ID was not provided');
            }
        } else {
            console.error("No token found");
        }
    };


    getAllSurveys = async (token, campaign_id, signal) => {
        if (token) {
            if (campaign_id) {
                const request = new Request(`${this.baseURL}/survey/all/?campaign_id=` + campaign_id, {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Authorization': `Bearer ${token}` },
                    signal: signal
                });

                const response = await fetch(request);

                if (!response.ok) {
                    throw new Error('Failed to fetch surveys');
                }

                const data = await response.json();
                return data;
            } else {
                throw new Error('Campaign ID was not provided');
            }
        } else {
            console.error("No token found");
        }
    };


    archiveSurvey = async (token, surveyId, campaignId) => {

        if (token) {

            if (surveyId && campaignId) {

                const request = new Request(`${this.baseURL}/survey/archive/?survey_id=` + surveyId + '&campaign_id=' + campaignId, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'X-client_type': 'web'
                    },
                    credentials: 'include',
                });

                const response = await fetch(request);

                if (!response.ok) {

                    throw new Error('Failed to archive survey');

                } else {

                    const data = await response.json();
                    // console.log(data);
                    return data;

                }

            }

            else {
                throw new Error('Survey ID or Campaign ID was not provided');
            }

        } else {

            throw new Error("No token found");

        }
    };





    // deleteSurvey = async (token, name) => {

    //     // if (token) {
    //     //     if (name) {
    //     //         console.log("Here 3");
    //     //         // Create request
    //     //         const request = new Request(`${this.baseURL}/voter-group/?name=` + name, {
    //     //             method: 'DELETE',
    //     //             credentials: 'include',
    //     //             //headers: {'Authorization': `Bearer ${token}`}
    //     //         });
    //     //         // Fetch request
    //     //         const response = await fetch(request);
    //     //         console.log("Here 4");
    //     //         console.log(response.status);


    //     //         if (response.status === 204) {
    //     //             return true;
    //     //         } else {
    //     //             const data = await response.json();
    //     //             if (data.detail) {
    //     //                 throw data.detail;
    //     //             }
    //     //             throw data;
    //     //         }
    //     //     } else {
    //     //         throw new Error('Survey Name was not provided');
    //     //     }
    //     // } else {
    //     //     console.error("No token found");
    //     // }
    // };

}

export default new Survey();