// import React from "react";
// import { useUserContext } from "./contexts/UserContext";


// // Lazy loading route components
// const Dashboard = React.lazy(() => import("./views/Dashboard.js"));
// const VoterGroupDashboard = React.lazy(() => import("./views/Voter/Group/VoterGroupDashboard.js"));
// const WalkbookDashboard = React.lazy(() => import("./views/Walkbook/WalkbookDashboard.js"));
// const RoadSignDashboard = React.lazy(() => import("./views/RoadSign/RoadSignDashboard.js"));
// const RecommendationDashboard = React.lazy(() => import("./views/Recommendations/Walkbook/RecommendationDashboard.js"));

// const TextCampaignDashboard = React.lazy(() => import("./views/TextCampaign/TextCampaignDashboard.js"));
// // const TextCampaignRegister = React.lazy(() => import("./views/TextCampaign/TextCampaignRegister.js"));

// const AccountView = React.lazy(() => import("./views/User/AccountView.js"));
// const CampaignPage = React.lazy(() => import("./views/Campaign/CampaignPage.js"));
// const DistrictPage = React.lazy(() => import("./views/District/DistrictPage.js"));
// const ElectionResultsPage = React.lazy(() => import("./views/ElectionResults/ElectionResultsPage.js"));


// const { campaign } = useUserContext();


// export const managerDirectorRoutes = [
//   {
//     path: "/dashboard",
//     layout: "/turrim",
//     name: "Dashboard",
//     icon: "material-icons-round",
//     icon_name: "grid_view",
//     component: Dashboard,
//   },
//   {
//     path: "/walkbooks",
//     layout: "/turrim",
//     name: "Walkbooks",
//     icon: "material-icons-round",
//     icon_name: "route",
//     component: WalkbookDashboard,
//   },
//   {
//     path: "/text-campaigns",
//     layout: "/turrim",
//     name: "Text Campaigns",
//     icon: "material-symbols-rounded",
//     icon_name: "perm_phone_msg",
//     // component: !campaign.is_texting_enabled ?TextCampaignRegister : TextCampaignDashboard,
//   },
//   {
//     path: "/road-signs",
//     layout: "/turrim",
//     name: "Road Signs",
//     icon: "material-icons-round",
//     icon_name: "signpost",
//     component: RoadSignDashboard,
//   },
//   {
//     path: "/recommendations",
//     layout: "/turrim",
//     name: "Recommendations",
//     icon: "material-icons-round",
//     icon_name: "insights",
//     component: RecommendationDashboard,
//   },
//   {
//     path: "/voter-groups",
//     layout: "/turrim",
//     name: "Voter Groups",
//     icon: "material-icons-round",
//     icon_name: "group",
//     component: VoterGroupDashboard,
//   },
//   {
//     collapse: true,
//     path: "/district",
//     name: "District",
//     state: "openDistrict",
//     // icon: "nc-icon nc-chart-bar-32",
//     icon: "material-icons-round",
//     icon_name: "landscape",
//     views: [
//       {
//         path: "/district/voters",
//         layout: "/turrim",
//         name: "District",
//         icon: "material-icons-round",
//         icon_name: "landscape",
//         mini: "DV",
//         component: DistrictPage,
//       },
//       {
//         path: "/district/election-results",
//         layout: "/turrim",
//         name: "Election Results",
//         icon: "material-icons-round",
//         icon_name: "landscape",
//         mini: "ER",
//         component: ElectionResultsPage,
//       },
//     ]
//   },
//   // {
//   //   path: "/district",
//   //   layout: "/turrim",
//   //   name: "District",
//   //   icon: "material-icons-round",
//   //   icon_name: "landscape",
//   //   component: DistrictPage,
//   // },
//   // {
//   //   path: "/election-results",
//   //   layout: "/turrim",
//   //   name: "Election Results",
//   //   icon: "material-icons-round",
//   //   icon_name: "landscape",
//   //   component: ElectionResultsPage,
//   // },
//   {
//     path: "/campaign",
//     layout: "/turrim",
//     name: "Campaign",
//     icon: "material-icons-round",
//     icon_name: "castle",
//     component: CampaignPage,
//   },
//   {
//     path: "/account",
//     layout: "/turrim",
//     name: "Account",
//     icon: "material-icons-round",
//     icon_name: "person",
//     component: AccountView,
//   }
// ];


// export const staffVolunteerRoutes = [
//   {
//     path: "/walkbooks",
//     layout: "/turrim",
//     name: "Walkbooks",
//     icon: "material-icons-round",
//     icon_name: "route",
//     component: WalkbookDashboard,
//   },
//   {
//     path: "/text-campaigns",
//     layout: "/turrim",
//     name: "Text Campaigns",
//     icon: "material-symbols-rounded",
//     icon_name: "perm_phone_msg",
//     component: TextCampaignDashboard,
//   },
//   {
//     path: "/road-signs",
//     layout: "/turrim",
//     name: "Road Signs",
//     icon: "material-icons-round",
//     icon_name: "signpost",
//     component: RoadSignDashboard,
//   },
//   {
//     path: "/campaign",
//     layout: "/turrim",
//     name: "Campaign",
//     icon: "material-icons-round",
//     icon_name: "castle",
//     component: CampaignPage,
//   },
//   {
//     path: "/account",
//     layout: "/turrim",
//     name: "Account",
//     icon: "material-icons-round",
//     icon_name: "person",
//     component: AccountView,
//   }
// ];


import React from "react";

// Lazy loading route components
const Dashboard = React.lazy(() => import("./views/Dashboard.js"));
const SurveyDashboard = React.lazy(() => import("./views/Survey/SurveyDashboard.js"));
const VoterGroupDashboard = React.lazy(() => import("./views/Voter/Group/VoterGroupDashboard.js"));
const WalkbookDashboard = React.lazy(() => import("./views/Walkbook/WalkbookDashboard.js"));
const RoadSignDashboard = React.lazy(() => import("./views/RoadSign/RoadSignDashboard.js"));
const RecommendationDashboard = React.lazy(() => import("./views/Recommendations/Walkbook/RecommendationDashboard.js"));
const MailDashboard = React.lazy(() => import("./views/Mail/MailDashboard.js"));
const TextCampaignDashboard = React.lazy(() => import("./views/TextCampaign/TextCampaignDashboard.js"));
const TextCampaignRegister = React.lazy(() => import("./views/TextCampaign/TextCampaignRegister.js"));
const PhoneBankingDashboard = React.lazy(() => import("./views/PhoneBanking/PhoneBankingDashboard.js"));
const AccountView = React.lazy(() => import("./views/User/AccountView.js"));
const CampaignPage = React.lazy(() => import("./views/Campaign/CampaignPage.js"));


const DistrictPage = React.lazy(() => import("./views/District/DistrictPage.js"));
const DistrictPageHosted = React.lazy(() => import("./views/District/DistrictPageHosted.js"));

const ElectionResultsPage = React.lazy(() => import("./views/ElectionResults/ElectionResultsPage.js"));
const ElectionResultsPageHosted = React.lazy(() => import("./views/ElectionResults/ElectionResultsPageHosted.js"));

// Create a wrapper component for Text Campaigns
const TextCampaignWrapper = React.lazy(() => import("./views/TextCampaign/TextCampaignWrapper.js"));

const PhoneBankingWrapper = React.lazy(() => import("./views/PhoneBanking/PhoneBankingWrapper.js"));

export const getManagerDirectorRoutes = (isTextingEnabled, isPhoneBankingEnabled) => [
  {
    path: "/dashboard",
    layout: "/turrim",
    name: "Dashboard",
    icon: "material-icons-round",
    icon_name: "grid_view",
    component: Dashboard,
  },
  {
    path: "/walkbooks",
    layout: "/turrim",
    name: "Walkbooks",
    icon: "material-icons-round",
    icon_name: "route",
    component: WalkbookDashboard,
  },
  // {
  //   collapse: true,
  //   path: "/sms-voice",
  //   name: "SMS & Voice",
  //   state: "openSMSVoice",
  //   // icon: "nc-icon nc-chart-bar-32",
  //   icon: "material-symbols-rounded",
  //   icon_name: "perm_phone_msg",
  //   views: [
  //     {
  //       path: "/sms-voice/text-campaigns",
  //       layout: "/turrim",
  //       name: "Texting",
  //       icon: "material-symbols-rounded",
  //       icon_name: "sms",
  //       mini: "TC",
  //       component: TextCampaignWrapper,
  //     },
  //     {
  //       path: "/sms-voice/phone-bankings",
  //       layout: "/turrim",
  //       name: "Phone Banking",
  //       icon: "material-symbols-rounded",
  //       icon_name: "phone",
  //       mini: "PB",
  //       component: PhoneBankingWrapper,
  //     },
  //   ]
  // },
  {
    path: "/road-signs",
    layout: "/turrim",
    name: "Road Signs",
    icon: "material-icons-round",
    icon_name: "signpost",
    component: RoadSignDashboard,
  },
  {
    path: "/recommendations",
    layout: "/turrim",
    name: "Recommendations",
    icon: "material-icons-round",
    icon_name: "insights",
    component: RecommendationDashboard,
  },
  {
    path: "/mail",
    layout: "/turrim",
    name: "Mail",
    icon: "material-symbols-rounded material-symbols-rounded-filled",
    icon_name: "mail",
    component: MailDashboard,
  },
  {
    path: "/voter-groups",
    layout: "/turrim",
    name: "Voter Groups",
    icon: "material-icons-round",
    icon_name: "group",
    component: VoterGroupDashboard,
  },
  {
    path: "/surveys",
    layout: "/turrim",
    name: "Surveys",
    // icon: "material-icons-round",
    // icon_name: "poll",
    icon: "fas fa-clipboard-list",
    component: SurveyDashboard,
  },
  {
    collapse: true,
    path: "/district",
    name: "District",
    state: "openDistrict",
    // icon: "nc-icon nc-chart-bar-32",
    icon: "material-icons-round",
    icon_name: "landscape",
    views: [
      // {
      //   path: "/district/voters",
      //   layout: "/turrim",
      //   name: "Voters",
      //   icon: "material-icons-round",
      //   icon_name: "landscape",
      //   mini: "DV",
      //   component: DistrictPage,
      // },
      {
        path: "/district/voters-hosted",
        layout: "/turrim",
        name: "Voters",
        icon: "material-icons-round",
        // icon_name: "landscape",
        icon_name: "groups_3",
        mini: "DV",
        // component: DistrictPage,
        // component: DistrictPageMVT,
        component: DistrictPageHosted,
      },
      {
        path: "/district/election-results",
        layout: "/turrim",
        name: "Election Results",
        icon: "material-icons-round",
        icon_name: "ballot",
        mini: "ER",
        component: ElectionResultsPage,
      },
      // {
      //   path: "/district/election-results-hosted",
      //   layout: "/turrim",
      //   name: "Election Results Hosted",
      //   icon: "material-icons-round",
      //   icon_name: "landscape",
      //   mini: "ER",
      //   component: ElectionResultsPageHosted,
      // },
    ]
  },
  // {
  //   path: "/district",
  //   layout: "/turrim",
  //   name: "District",
  //   icon: "material-icons-round",
  //   icon_name: "landscape",
  //   component: DistrictPage,
  // },
  // {
  //   path: "/election-results",
  //   layout: "/turrim",
  //   name: "Election Results",
  //   icon: "material-icons-round",
  //   icon_name: "landscape",
  //   component: ElectionResultsPage,
  // },
  {
    path: "/campaign",
    layout: "/turrim",
    name: "Campaign",
    icon: "material-icons-round",
    icon_name: "castle",
    component: CampaignPage,
  },
  {
    path: "/account",
    layout: "/turrim",
    name: "Account",
    icon: "material-icons-round",
    icon_name: "person",
    component: AccountView,
  }
];

export const getStaffVolunteerRoutes = (isTextingEnabled, isPhoneBankingEnabled) => {
  const baseRoutes = [
    {
      path: "/walkbooks",
      layout: "/turrim",
      name: "Walkbooks",
      icon: "material-icons-round",
      icon_name: "route",
      component: WalkbookDashboard,
    },
    {
      path: "/road-signs",
      layout: "/turrim",
      name: "Road Signs",
      icon: "material-icons-round",
      icon_name: "signpost",
      component: RoadSignDashboard,
    },
    {
      path: "/campaign",
      layout: "/turrim",
      name: "Campaign",
      icon: "material-icons-round",
      icon_name: "castle",
      component: CampaignPage,
    },
    {
      path: "/account",
      layout: "/turrim",
      name: "Account",
      icon: "material-icons-round",
      icon_name: "person",
      component: AccountView,
    }
  ];

  // if (isTextingEnabled || isPhoneBankingEnabled) {
  //   const smsVoiceViews = [];

  //   if (isTextingEnabled) {
  //     smsVoiceViews.push({
  //       path: "/sms-voice/text-campaigns",
  //       layout: "/turrim",
  //       name: "Texting",
  //       icon: "material-symbols-rounded",
  //       icon_name: "sms",
  //       mini: "TC",
  //       component: TextCampaignDashboard,
  //     });
  //   }

  //   if (isPhoneBankingEnabled) {
  //     smsVoiceViews.push({
  //       path: "/sms-voice/phone-bankings",
  //       layout: "/turrim",
  //       name: "Phone Banking",
  //       icon: "material-symbols-rounded",
  //       icon_name: "phone",
  //       mini: "PB",
  //       component: PhoneBankingDashboard,
  //     });
  //   }

  //   baseRoutes.splice(1, 0, {
  //     collapse: true,
  //     path: "/sms-voice",
  //     name: "SMS & Voice",
  //     state: "openSMSVoice",
  //     icon: "material-symbols-rounded",
  //     icon_name: "perm_phone_msg",
  //     views: smsVoiceViews,
  //   });
  // }

  return baseRoutes;
};