// mail.js
class Mail {
  constructor() {
    this.baseURL = process.env.REACT_APP_API_BASE_URL;
  }

  createMailer = async (token, name, campaign_id, pdf_file) => {
    const formData = new FormData();
    formData.append('file', pdf_file, pdf_file.name);

    const mailerData = {
      "name": name,
      // "filters": filters,
      "campaign_id": campaign_id
    };

    const jsonData = JSON.stringify(mailerData);
    const blob = new Blob([jsonData], {
      type: 'application/json'
    });
    formData.append('mailer', blob);

    if (token) {
      const request = new Request(`${this.baseURL}/mail/`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        body: formData,
        credentials: 'include',
      });

      const response = await fetch(request);
      const data = await response.json();

      if (response.status === 500) {
        throw new Error('Internal server error');
      }

      if (response.status >= 400 && response.status < 500) {
        if (data.detail) {
          throw new Error(data.detail);
        }
        if (typeof data === 'string') {
          throw new Error(data);
        }
        if (typeof data === 'object') {
          if (data['error'] === 'NAME_EXISTS') {
            throw new Error('This name is already in use. Please choose a different name.');
          }
        }
        throw new Error('An error occurred');
      }

      return data;
    } else {
      console.error("No token found");
    }
  };


  checkUniqueMailerName = async (token, campaignId, name) => {
    if (token) {
      const request = new Request(`${this.baseURL}/mail/check-name/mailer/${campaignId}/?name=${encodeURIComponent(name)}`, {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        credentials: 'include',
      });

      const response = await fetch(request);
      if (!response.ok) {
        throw new Error('Failed to check mailer name uniqueness');
      }
      const data = await response.json();
      return data.is_unique;
    } else {
      throw new Error("No token found");
    }
  };


  getMailer = async (token, id, campaign_id) => {
    if (token) {
      if (id && campaign_id) {

        const request = new Request(`${this.baseURL}/mail/?id=${id}&campaign_id=${campaign_id}`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          }
        });

        const response = await fetch(request);
        const data = await response.json();
        return data;
      } else {
        throw new Error('ID was not provided');
      }
    } else {
      console.error("No token found");
    }
  };

  getAllMailers = async (token, campaign_id, signal) => {
    if (token && campaign_id) {
      const request = new Request(`${this.baseURL}/mail/all/?campaign_id=${campaign_id}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        signal: signal
      });

      const response = await fetch(request);
      const data = await response.json();
      return data;
    } else {
      throw new Error('Campaign ID was not provided');
    }
  };



  createMailerCampaign = async (token, mailerId, campaignId, userId, voterGroupId, name, mailerSize, scheduledDate = null) => {
    
    const formData = {
      "mailer_id": mailerId,
      "campaign_id": campaignId,
      "user_id": userId,
      "voter_group_id": voterGroupId,
      "name": name,
      "mailer_size": mailerSize,
      "scheduled_date": scheduledDate
    }

    console.log(formData);
    
    
    if (token) {
      const request = new Request(`${this.baseURL}/mail/mailer-campaign/`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        body: JSON.stringify(formData),
        credentials: 'include',
      });
  
      const response = await fetch(request);
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.detail) {
          throw new Error(errorData.detail);
        }
        throw new Error('Failed to create mailer campaign');
      }
  
      const data = await response.json();
      return data;
    } else {
      throw new Error("No token found");
    }
  };


  checkUniqueMailerCampaignName = async (token, mailerId, campaignId, name) => {
    if (token) {
      const request = new Request(`${this.baseURL}/mail/check-name/mailer-campaign/${campaignId}/?name=${encodeURIComponent(name)}&mailer_id=${encodeURIComponent(mailerId)}`, {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        credentials: 'include',
      });

      const response = await fetch(request);
      if (!response.ok) {
        throw new Error('Failed to check mailer name uniqueness');
      }
      const data = await response.json();
      return data.is_unique;
    } else {
      throw new Error("No token found");
    }
  };

};

export default new Mail();